import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { Link } from "react-router-dom";
import CustomImage from "./CustomImage";
import AddToCart from "./AddToCart";
import { showModal } from "./Methods";

export default function HeaderCartDetails() {
  const [data, setData] = useState();
  const [firstLoading, setFirstLoading] = useState(true);
  const [cartPrice, setCartPrice] = useState(0);
  const firstLoad = useRef(true);
  const {
    cartContext: [cartData, setCartData],
    userData: [userInfo],
  } = useContext(AppContext);
  useEffect(() => {
    if (firstLoad.current && userInfo.tkn) {
      firstLoad.current = false;
      fetchData();
    }
  }, [userInfo]);
  useEffect(() => {
    calculateCartPrice();
    setData(cartData);
  }, [cartData]);
  useEffect(() => {
    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(fetchDataInterval);
  }, []);
  function fetchData() {
    if (!userInfo.tkn) {
      return;
    }
    const formData = new FormData();
    formData.append("token", userInfo.tkn);
    fetch("https://crm.bamamiresi.com/Enrollment/IGetUserBasket", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        if (firstLoading) {
          setFirstLoading(false);
        }
        const serverResponse = json;
        if (serverResponse) {
          setData(serverResponse);
          setCartData(serverResponse);
        }
      })
      .catch((err) => {});
  }
  function calculateCartPrice() {
    let tempPrice = 0;
    cartData.forEach((item) => {
      if (+item.discountPrice > 0) {
        tempPrice +=
          (+item.price - (+item.price * +item.discountPrice) / 100) *
          +item.quantity;
      } else {
        tempPrice += +item.price * +item.quantity;
      }
    });
    setCartPrice(tempPrice);
  }
  function changeCart(number, type, id) {
    const data = new FormData();
    data.append("token", userInfo.tkn);
    data.append("PaymentType", 1);
    data.append("productId", id);
    data.append("Quantity", number);
    data.append("orderType", type);
    data.append("ClassID", 0);
    fetch("https://crm.bamamiresi.com/Enrollment/IAddToBasket", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        const serverRes = json[0];
        if (+serverRes.code !== 200) {
          throw new Error(
            serverRes.message ? serverRes.message : "عدم ارتباط با سرور"
          );
        }
        setCartData(serverRes.basket);
      })
      .catch((err) => {
        showModal(err.message);
      });
  }
  return (
    <>
      {data && data.length > 0 && (
        <p className="cartNumberText faFont flex flexCenter">{data.length}</p>
      )}
      <div className="flex flexCol cartHoverDiv">
        <div className="flex flexCol cartHoverSubDiv">
          {firstLoading ? (
            <>
              <div className="flex flexCol flexCenter cartHoverLoadingDiv rel">
                <div className="cartHeaderSpin"></div>
              </div>
            </>
          ) : (
            <>
              {data && data.length > 0 ? (
                <>
                  <div className="headerHasDataList grid">
                    <div className="flex alignCenter justifyBetween headerHasHeaderDiv">
                      <p className="faFont headerHasItemName">سبد خرید</p>
                      <Link
                        className="faFont headerHasItemLink"
                        to={"/cart"}
                        draggable="false"
                      >
                        مشاهده سبد
                      </Link>
                    </div>
                    <div className="flex flexCol headerCartList customScrollbar">
                      {data.map((item) => {
                        return (
                          <div
                            className="headerCartItem flex flexCol"
                            key={item.product[0].id}
                          >
                            <div className="cartItemTopDiv grid">
                              <Link
                                to={item.product[0].url}
                                target="_blank"
                                className="flex flexCenter"
                              >
                                <CustomImage
                                  data={{
                                    src: item.product[0].src,
                                    alt: item.product[0].title,
                                    class: "cartItemImage",
                                    height: 70,
                                    width: 70,
                                  }}
                                />
                              </Link>
                              <div className="flex flexCol headerCartItemInfo">
                                <Link
                                  to={item.product[0].url}
                                  className="faFont headerCartItemName"
                                >
                                  {item.product[0].title}
                                </Link>
                                <div className="flex flexCol headerCartItemPriceDiv rel">
                                  {item.discountPrice > 0 && (
                                    <div className="flex justifyBetween">
                                      <p className="faFont headerCartItemPercent flex alignCenter">
                                        <svg
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeWidth="0"
                                          viewBox="0 0 16 16"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M3.60962 12.6877L11.6096 2.68765L12.3905 3.31235L4.39049 13.3123L3.60962 12.6877Z"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 7.5C5.88071 7.5 7 6.38071 7 5C7 3.61929 5.88071 2.5 4.5 2.5C3.11929 2.5 2 3.61929 2 5C2 6.38071 3.11929 7.5 4.5 7.5Z"
                                          ></path>
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M11.5 12.5C10.6716 12.5 10 11.8284 10 11C10 10.1716 10.6716 9.5 11.5 9.5C12.3284 9.5 13 10.1716 13 11C13 11.8284 12.3284 12.5 11.5 12.5ZM11.5 13.5C12.8807 13.5 14 12.3807 14 11C14 9.61929 12.8807 8.5 11.5 8.5C10.1193 8.5 9 9.61929 9 11C9 12.3807 10.1193 13.5 11.5 13.5Z"
                                          ></path>
                                        </svg>
                                        {+item.discountPrice}
                                      </p>
                                      <p className="faFont headerCartItemPriceLine">
                                        {(+item.price).toLocaleString()}
                                      </p>
                                    </div>
                                  )}
                                  <p className="faFont headerCartItemPrice">
                                    {+item.discountPrice > 0
                                      ? (
                                          +item.price -
                                          (+item.price * +item.discountPrice) /
                                            100
                                        ).toLocaleString()
                                      : (+item.price).toLocaleString()}
                                    <span> تومان </span>
                                  </p>
                                </div>
                                <div className="headerCartItemManageDiv flex alignCenter justifyBetween">
                                  <button
                                    className="headerCartItemRemove flex flexCenter"
                                    onClick={() => {
                                      changeCart(
                                        0,
                                        item.OrderType,
                                        item.product[0].id
                                      );
                                    }}
                                  >
                                    <svg
                                      stroke="currentColor"
                                      fill="none"
                                      strokeWidth="2"
                                      viewBox="0 0 24 24"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M3 6h18"></path>
                                      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                      <line
                                        x1="10"
                                        x2="10"
                                        y1="11"
                                        y2="17"
                                      ></line>
                                      <line
                                        x1="14"
                                        x2="14"
                                        y1="11"
                                        y2="17"
                                      ></line>
                                    </svg>
                                    <span className="hideText">remove</span>
                                  </button>
                                  <AddToCart
                                    data={{
                                      id: item.product[0].id,
                                      type: item.type,
                                      number: item.quantity,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="headerCartFooter flex alignCenter justifyBetween">
                      <p className="faFont headerCartSummaryText">جمع کل :</p>
                      <p className="faFont headerTotalPrice">
                        {cartPrice.toLocaleString()}
                        <span>تومان</span>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flexCol emptyCartDiv customScrollbar">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 256 256"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M232,72l-25.63,92.28A16,16,0,0,1,191,176H92.16a16,16,0,0,1-15.41-11.72L51.11,72Z"
                        opacity="0.2"
                      ></path>
                      <path d="M104,216a16,16,0,1,1-16-16A16,16,0,0,1,104,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,192,200ZM239.71,74.14l-25.64,92.28A24.06,24.06,0,0,1,191,184H92.16A24.06,24.06,0,0,1,69,166.42L33.92,40H16a8,8,0,0,1,0-16H40a8,8,0,0,1,7.71,5.86L57.19,64H232a8,8,0,0,1,7.71,10.14ZM221.47,80H61.64l22.81,82.14A8,8,0,0,0,92.16,168H191a8,8,0,0,0,7.71-5.86Z"></path>
                    </svg>
                    <p className="faFont textSize1 textClr5 flex flexCenter">
                      سبد خرید خالی است.
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
