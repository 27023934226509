import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { showModal } from "./Methods";
export default function AddToCart({ data }) {
  const [itemData, setItemData] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    cartContext: [cartData, setCartData],
    userData: [userInfo],
  } = useContext(AppContext);
  useEffect(() => {
    if (cartData) {
      let newItem = null;
      cartData.forEach((item) => {
        if (item.product[0].id == data.id) {
          newItem = item;
        }
      });
      setItemData(newItem);
    }
  }, [cartData]);
  function changeCart(number, type, id) {
    setLoading(true);
    const data = new FormData();
    data.append("token", userInfo.tkn);
    data.append("PaymentType", 1);
    data.append("productId", id);
    data.append("Quantity", number);
    data.append("orderType", type);
    data.append("ClassID", 0);
    fetch("https://crm.bamamiresi.com/Enrollment/IAddToBasket", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        const serverRes = json[0];
        if (+serverRes.code !== 200) {
          throw new Error(
            serverRes.message ? serverRes.message : "عدم ارتباط با سرور"
          );
        }
        setLoading(false);
        setCartData(serverRes.basket);
      })
      .catch((err) => {
        showModal(err.message);
      });
  }
  return (
    <>
      {itemData || (itemData && itemData.number > 0) ? (
        <>
          <div className="grid productCartManageDiv rel">
            <button
              className="increaseNumberBtn flex flexCenter"
              onClick={() => changeCart(1, data.type, data.id)}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="19"
                width="19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <span className="hideText">increase</span>
            </button>
            <p className="faFont showProductNumber flex flexCenter">
              {itemData.quantity}
            </p>
            <button
              className="decreaseNumberBtn flex flexCenter"
              onClick={() => changeCart(-1, data.type, data.id)}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg>
              <span className="hideText">decrease</span>
            </button>
            {loading && (
              <p className="addCartLoading flex flexCenter">
                <span className="addCartLoadingDot"></span>
                <span className="addCartLoadingDot"></span>
                <span className="addCartLoadingDot"></span>
              </p>
            )}
          </div>
        </>
      ) : (
        <>
          <button
            className="cartAddBtn faFont flex flexCenter rel"
            onClick={() => changeCart(1, data.type, data.id)}
          >
            افزودن به سبد خرید
            {loading && (
              <p className="addCartLoading flex flexCenter">
                <span className="addCartLoadingDot"></span>
                <span className="addCartLoadingDot"></span>
                <span className="addCartLoadingDot"></span>
              </p>
            )}
          </button>
        </>
      )}
    </>
  );
}
