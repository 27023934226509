import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Style/footer.css";
import { AppContext } from '../App';

export default function Footer(){
    const {siteInfo} = useContext(AppContext);
    const [siteData,] = siteInfo;
    const [data,setData] = useState({
        address : "",
        about : "",
        email : "",
        phone1 : "",
        social : "",
    });
    useEffect(() => {
        if(siteData){
            setData(siteData);
         }
    }, [siteData]);
    return <footer className="flex flexCol">
        <div className="flex flexCol footerContainer container">
            <button className="scrollToTopBtn flex flexCenter">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>
            </button>
            <div className="grid footerGrid">
                <div className="flex flexCol">
                    <p className="faFont textSize12 textClr3 footerDesc">
                        {
                            data.about
                        }
                    </p>
                </div>
                <div className="flex flexCol">
                    <h4 className="faFont textSize11 textClr3 footerLinkTitle">لینک های پیشنهادی</h4>
                    <div className="flex flexCol popularFooterLink">
                        <Link to="/courses" className="faFont rel footerLinkItem textSize1 textClr3">دوره ها</Link>
                        <Link to="/teachers" className="faFont rel footerLinkItem textSize1 textClr3">اساتید</Link>
                        <Link to="/blogs" className="faFont rel footerLinkItem textSize1 textClr3">مقالات</Link>
                        <Link to="/aboutus" className="faFont rel footerLinkItem textSize1 textClr3">درباره ما</Link>
                        <Link to="/contactus" className="faFont rel footerLinkItem textSize1 textClr3">تماس با ما</Link>
                    </div>
                </div>
                <div className="flex flexCol">
                    <h3 className="faFont textSize11 textClrF contactUsTitle">
                        راه های تماس با ما
                    </h3>
                    <a href={`tel:${data.phone1}`} className="faFont footerCall textSize9 flex alignCenter">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M19.502 3.672l-1.795-1.793c-.566-.567-1.32-.879-2.121-.879s-1.555.312-2.121.879l-1.586 1.586c-1.17 1.17-1.17 3.072 0 4.242l1.379 1.379-4.172 4.172-1.379-1.379c-.566-.567-1.32-.879-2.121-.879s-1.555.312-2.121.879l-1.586 1.586c-1.17 1.17-1.17 3.072 0 4.242l1.794 1.793c.465.465 1.796 1.545 4.116 1.545 2.764 0 5.694-1.529 8.711-4.545 6.245-6.246 4.825-11.002 3.002-12.828zm-6.209 1.207l1.586-1.586c.195-.195.451-.293.707-.293s.512.098.707.293l1.083 1.082-3.001 3-1.082-1.082c-.391-.391-.391-1.023 0-1.414zm-10 11.414c-.391-.391-.391-1.023 0-1.414l1.586-1.586c.195-.195.451-.293.707-.293s.512.098.707.293l1.082 1.082-2.999 3-1.083-1.082zm11.793-1.207c-3.083 3.082-5.551 3.959-7.297 3.959-1.349 0-2.267-.523-2.702-.959-.004-.004 2.995-3.004 2.995-3.004l.297.297c.195.195.451.293.707.293s.512-.098.707-.293l5.586-5.586c.391-.391.391-1.023 0-1.414l-.297-.297 3.001-3c1.003 1.004 2.467 4.539-2.997 10.004z"></path></svg>
                        <span>
                            {
                                data.phone1
                            }
                        </span>
                    </a>
                    <a href={`mailto:${data.email}`} className="faFont footerEmail textSize9 flex alignCenter">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path><path d="M3 7l9 6l9 -6"></path></svg>
                        <span>{data.email}</span>
                    </a>
                    <p className="faFont footerAddress textSize9 flex alignCenter">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path><path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path></svg>
                        <span>{data.address}</span>
                    </p>
                    <div className="flex flexCenter footerSocialLinks">
                        <a href="" className="flex flexCenter footerSocailItem textClr3">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M349.33 69.33a93.62 93.62 0 0 1 93.34 93.34v186.66a93.62 93.62 0 0 1-93.34 93.34H162.67a93.62 93.62 0 0 1-93.34-93.34V162.67a93.62 93.62 0 0 1 93.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"></path><path d="M377.33 162.67a28 28 0 1 1 28-28 27.94 27.94 0 0 1-28 28zM256 181.33A74.67 74.67 0 1 1 181.33 256 74.75 74.75 0 0 1 256 181.33m0-37.33a112 112 0 1 0 112 112 112 112 0 0 0-112-112z"></path></svg>
                        </a>
                        <a href="" className="flex flexCenter footerSocailItem textClr3">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path></svg>
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex flexCol footerCopyWrite flexCenter">
                <p className="faFont copyWriteText">
                    تمامی حقوق برای سایت <span>با ما می ر سی</span> محفوظ است
                </p>
            </div>
        </div>
    </footer>
}