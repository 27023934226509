import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import NavsList from "./NavsList";
import "../Style/teacherPage.css"
import { scrollToTop } from "./Methods";
import CourseItem from "./CourseItem";
import PagePagintaion from "./PagePagination";
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import FullVideo from "./FullVideo";
import ImageFullSlider from "./ImageFullSlider";
export default function TeacherDetails(){
    const param = useParams();
    const navigate = useNavigate();
    const [data,setData] = useState({
        teacherName : "",
        teacherData : "",
        totalPage : 0,
        teacherGallery:[]
    });
    const [firstLoading,setFirstLoading] = useState(true);
    
    const [fullAbout,setFullAbout] = useState(false);
    const [searchParam,] = useSearchParams();
    const pageNumber = useRef(1);
    const teacherCourseSection = useRef(null);
    const [showFullVideo ,setShowFullVideo] = useState(false);
    const [showFullImageSlider ,setShowFullImageSlider] = useState(0);
    const firstLoad = useRef(true);
    useEffect(() => {
        if(firstLoad.current){
            firstLoad.current = false;
            scrollToTop();
            fetchData();
        }
    }, []);
    function fetchData(){
        const teacherId = param.id;
        const data = new FormData();
        data.append("ID", teacherId);
        fetch("https://crm.bamamiresi.com/Enrollment/GetTeacher",{ 
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            console.log(json);
            if (json.error) {
                throw new Error(json.msg);
            }
            scrollToTop();
            setFirstLoading(false);
        })
        .catch((err) => {
            console.log(err);
            // navigate("/505") 
            setFirstLoading(false);
            const serverData = {
                teacherName:"محمد راد",
                teacherId : 1,
                teacherGallery :[
                    {
                        img : "/image/teacherImg.webp",
                        name : "شروع فعالیت"
                    },
                ],
                teacherCourse :[
                    {
                        url : "/course/254",
                        title : "نصب یونیتی ۶",
                        teacher : "مدائنی",
                        teacherId : "25",
                        duration : "۰۱:۲۰:۰۰",
                        price : 0,
                        cover : "/image/courseImg.webp",
                        discount : 40,
                    },
                ],
                teacherData :{
                    name : "محمد راد",
                    cover : "/image/teacherImg.webp",
                    expert : "برنامه نویس فرانت ، ری اکت ، نکست js",
                    blogNumber : 10,
                    courseNumber : 15,
                    podcastNumber : 1,
                    videosNumber :5,
                    about : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
                    
                },
                totalPage : 10,
                videoLink : "/video/horizontalVideo.mp4",
                videoCover : "/image/courseImg.webp",
            }
            setData({...serverData,loading : false});
        });
    }
    function changePage(newPageNumber){
        if(!teacherCourseSection.current){
            teacherCourseSection.current = document.getElementById("teacherCourseSection");
        }
        teacherCourseSection.current.scrollIntoView();
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    return <>
        <NavsList data={[{name:"اساتید",link:"/teachers"},{name:data.teacherName,link:"/teacher"}]} />
        {
            firstLoading ? <div className="flex flexCol teacherInfoSectionLoading container">
                <div className="flex flexCol teacherInfoDivLoading rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol teacherGaalleryDiv rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol teacherGaalleryDiv rel">
                    <div className="loaderItem"></div>
                </div>
            </div>
            :
            <>
                <main className="teacherMainGrid grid container">
                    <section className="mainTeacherGridRightDiv">
                        <div className="flex topTeacherSection">
                            <div className="flex flexCol teacherInfoSection ">
                                <div className="flex flexCol">
                                    <div className="flex flexCol teacherImgDiv">
                                        <img src={data.teacherData.cover} alt={data.teacherData.name} className="teacherDetailsImg" />
                                    </div>
                                    <div className="flex flexCol">
                                        <h1 className="faFont textSize12 textClr3 teacherDetailsName">
                                        {
                                            data.teacherData.name
                                        }
                                    </h1>
                                    <p className="faFont textSize9 textClr7 teacherJobText">
                                        {
                                            data.teacherData.expert
                                        }
                                    </p>
                                    </div>
                                </div>
                            </div>
                            <div className="grid teacherSummGrid">
                                <div className="teacherSummItem flex flexCol alignCenter rel">
                                    <p className="faFont summNumber textClr3 textSize16">
                                    {
                                        data.teacherData.blogNumber
                                    }
                                    </p>
                                    <p className="faFont textSize1 textClr7 summText">
                                        مقالات
                                    </p>
                                </div>
                                <div className="teacherSummItem flex flexCol alignCenter rel">
                                    <p className="faFont summNumber textClr3 textSize16">
                                    {
                                        data.teacherData.courseNumber
                                    }
                                    </p>
                                    <p className="faFont textSize1 textClr7 summText">
                                        دوره ها
                                    </p>
                                </div>
                                <div className="teacherSummItem flex flexCol alignCenter rel">
                                    <p className="faFont summNumber textClr3 textSize16">
                                    {
                                        data.teacherData.videosNumber
                                    }
                                    </p>
                                    <p className="faFont textSize1 textClr7 summText">
                                        ویدیوها
                                    </p>
                                </div>
                                <div className="teacherSummItem flex flexCol alignCenter rel">
                                    <p className="faFont summNumber textClr3 textSize16">
                                    {
                                        data.teacherData.podcastNumber
                                    }
                                    </p>
                                    <p className="faFont textSize1 textClr7 summText">
                                        پادکست ها
                                    </p>
                                </div>
                            </div>
                            <button className="flex flexCol courseFirstLeftDiv rel" onClick={()=>setShowFullVideo(true)}>
                                <img src={data.videoCover} alt={data.title} className="courseLeftDivImg" width={"100%"} draggable="false" />
                                <svg className="coursePlayIcon" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg>
                            </button>
                            <FullVideo data={{videoLink : data.videoLink}} showFullVideo={showFullVideo} setShowFullVideo={setShowFullVideo} />
                        </div>
                        <div className="flex flexCol teacherGallerySection">
                            <h3 className="faFont textClr3 teacherCourseTitle flex alignCenter textSize12">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0"></path></svg>
                                زندگی حرفه‌ای استاد به روایت تصویر
                            </h3>
                            <Swiper className="swiper videoSwiper rel"
                                spaceBetween={10}
                                slidesPerView={2}
                                breakpoints={{
                                    968:{
                                        slidesPerView: 3,
                                    },
                                    1250:{
                                        slidesPerView: 4,
                                        spaceBetween : 20
                                    },
                                }}
                            >
                                {
                                data.teacherGallery.map((item,index)=>{
                                    return <SwiperSlide key={index} onClick={()=>{setShowFullImageSlider(index + 1)}}>
                                        <div className="flex flexCol teacherGalleryItemDiv rel">
                                            <img src={item.img} alt={item.name} className="teacherGalleryImg" />
                                            <p className="faFont textSize1 teacherGalleryText">
                                                {
                                                    item.name
                                                }
                                            </p>
                                        </div>
                                </SwiperSlide>
                                })
                                }
                            </Swiper>
                        </div>
                    </section>
                    <section className="flex flexCol teacherSectionLeftDiv">
                        <div className="flex flexCol teacherReserverDiv">
                            <h3 className="faFont reserveTitle">
                                رزرو کلاس خصوصی
                            </h3>
                            <p className="faFont reserveText">
                                برای رزرو کلاس خصوصی و آموزش توسط استاد 
                                {
                                    data.teacherData.name
                                }
                                به صورت حضوری و آنلاین بر روی لینک زیر کلیک کنید.
                            </p>
                            <Link to={`/reserve/${data.teacherId}`} className="faFont reserveLink" >
                                الان رزرو کنید
                            </Link>
                        </div>
                        <div className="flex flexCol teacherInfoList">
                            <p className="faFont teacherLeftInfoItem flex alignCenter gap5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinejoin="round" strokeWidth="32" d="M336 264.13V436c0 24.3-19.05 44-42.95 44H107c-23.95 0-43-19.7-43-44V172a44.26 44.26 0 0 1 44-44h94.12a24.55 24.55 0 0 1 17.49 7.36l109.15 111a25.4 25.4 0 0 1 7.24 17.77z"></path><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M200 128v108a28.34 28.34 0 0 0 28 28h108"></path><path fill="none" strokeLinejoin="round" strokeWidth="32" d="M176 128V76a44.26 44.26 0 0 1 44-44h94a24.83 24.83 0 0 1 17.61 7.36l109.15 111A25.09 25.09 0 0 1 448 168v172c0 24.3-19.05 44-42.95 44H344"></path><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M312 32v108a28.34 28.34 0 0 0 28 28h108"></path></svg>
                                <span>
                                    تعداد مقالات :
                                </span>
                                <span>
                                {
                                    data.teacherData.blogNumber
                                }
                                </span>
                            </p>
                            <p className="faFont teacherLeftInfoItem flex alignCenter gap5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
                                <span>
                                    تعداد  دوره ها :
                                </span>
                                <span>
                                {
                                    data.teacherData.blogNumber
                                }
                                </span>
                            </p>
                            <p className="faFont teacherLeftInfoItem flex alignCenter gap5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M374.79 308.78 457.5 367a16 16 0 0 0 22.5-14.62V159.62A16 16 0 0 0 457.5 145l-82.71 58.22A16 16 0 0 0 368 216.3v79.4a16 16 0 0 0 6.79 13.08z"></path><path fill="none" strokeMiterlimit="10" strokeWidth="32" d="M268 384H84a52.15 52.15 0 0 1-52-52V180a52.15 52.15 0 0 1 52-52h184.48A51.68 51.68 0 0 1 320 179.52V332a52.15 52.15 0 0 1-52 52z"></path></svg>
                                <span>
                                    تعداد ویدیوها :
                                </span>
                                <span>
                                {
                                    data.teacherData.blogNumber
                                }
                                </span>
                            </p>
                            <p className="faFont teacherLeftInfoItem flex alignCenter gap5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M14 12c0 .74-.4 1.38-1 1.72V22h-2v-8.28c-.6-.35-1-.98-1-1.72 0-1.1.9-2 2-2s2 .9 2 2zm-2-6c-3.31 0-6 2.69-6 6 0 1.74.75 3.31 1.94 4.4l1.42-1.42A3.957 3.957 0 0 1 8 12c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.19-.53 2.25-1.36 2.98l1.42 1.42A5.957 5.957 0 0 0 18 12c0-3.31-2.69-6-6-6zm0-4C6.48 2 2 6.48 2 12c0 2.85 1.2 5.41 3.11 7.24l1.42-1.42A7.987 7.987 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 2.29-.98 4.36-2.53 5.82l1.42 1.42C20.8 17.41 22 14.85 22 12c0-5.52-4.48-10-10-10z"></path></svg>
                                <span>
                                    تعداد پادکست ها :
                                </span>
                                <span>
                                {
                                    data.teacherData.blogNumber
                                }
                                </span>
                            </p>
                        </div>
                    </section>
                </main>
                <ImageFullSlider data={{list :data.teacherGallery}} showFullImageSlider={showFullImageSlider} setShowFullImageSlider={setShowFullImageSlider} /> 
                <section className="flex flexCol container aboutSection">
                    <h2 className="faFont textSize12 aboutTitle flex alignCenter">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M319.4 320.6L224 416l-95.4-95.4C57.1 323.7 0 382.2 0 454.4v9.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-9.6c0-72.2-57.1-130.7-128.6-133.8zM13.6 79.8l6.4 1.5v58.4c-7 4.2-12 11.5-12 20.3 0 8.4 4.6 15.4 11.1 19.7L3.5 242c-1.7 6.9 2.1 14 7.6 14h41.8c5.5 0 9.3-7.1 7.6-14l-15.6-62.3C51.4 175.4 56 168.4 56 160c0-8.8-5-16.1-12-20.3V87.1l66 15.9c-8.6 17.2-14 36.4-14 57 0 70.7 57.3 128 128 128s128-57.3 128-128c0-20.6-5.3-39.8-14-57l96.3-23.2c18.2-4.4 18.2-27.1 0-31.5l-190.4-46c-13-3.1-26.7-3.1-39.7 0L13.6 48.2c-18.1 4.4-18.1 27.2 0 31.6z"></path></svg>
                        درباره ی مدرس
                    </h2>
                    <div className={fullAbout ? "flex flexCol aboutBodyDiv full" : "flex flexCol aboutBodyDiv"}>
                        <p className="faFont textSize1 textClr5 aboutMainText">
                            {
                                data.teacherData.about
                            }
                        </p>
                        <div className="aboutBg"></div>
                        <div className="aboutBg2"></div>
                        <button className="aboutFullBtn faFont textSize9 textClr3" onClick={()=>setFullAbout(true)}>
                            مشاهده بیشتر
                        </button>
                    </div>
                </section>
                <section className="flex flexCol teacherCourseSection container" id="teacherCourseSection">
                    <h3 className="faFont textClr3 teacherCourseTitle flex alignCenter textSize12">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
                        دوره های درس
                    </h3>
                    <div className="grid teacherCourseGrid">
                        {
                            data.teacherCourse.map((item,index)=>{
                                return <CourseItem data={item} key={index} />
                            })
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                        changePage : changePage, currentPage:pageNumber}} />
                </section>
            </>
        }
    </>
}