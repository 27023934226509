import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { cutText, scrollToTop } from "./Methods";
import CustomImage from "./CustomImage";
import "../Style/profileOrders.css";
import ProfileSubLoading from "./ProfileSubLoading";
import PagePagintaion from "./PagePagination";
import EmptyResult from "./EmptyResult";
export default function ProfileOrders({changeMenuHandler}){
    const navigate = useNavigate();
    const [data,setData] = useState({});
    const [searchParam,] = useSearchParams();
    const pageNumber = useRef(1);
    const [firstLoading ,setFirstLoading] = useState(true);
    const firstLoad = useRef(true);
    useEffect(() => {
        document.title = "لیست سفارش ها";
        if(firstLoad.current){
            firstLoad.current = false;
            fetchUserOrders();
        }
    }, []);
    function fetchUserOrders(){
        const data = new FormData();
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
        data.append("pageCount", 10);
        data.append("pageIndex", pageNumber.current);
        data.append("token","");
        fetch("https://crm.bamamiresi.com/Enrollment/getUserOrders",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            setFirstLoading(false);
            const serverData = json[0];
            if (serverData.code !== 200) {
                throw new Error("Error happend");
            }
            setData(serverData);
        })
        .catch((err) => {
            console.log(err);
            // navigate("/505");
            setFirstLoading(false);
            const serverRes = {
                orderList : [
                    {
                        id : 21168819,
                        name : "کتاب آموزش پیانو و ۲ مورد دیگر",
                        state : "inProgress",
                        stateText : "در حال پیگیری",
                        price : "125000",
                        // کد پیگیری سفارش
                        trackingCode : "26500841",
                        img  : "/image/blogCover.jpg",
                    },
                    {
                        id : 1878177179/7,
                        name : "کتاب آموزش بازیگری و ۲ مورد دیگر",
                        state : "complete",
                        stateText : "تحویل داده شده",
                        price : "650000",
                        // کد پیگیری سفارش
                        trackingCode : "26500841",
                        img  : "/image/blogCover.jpg",
                    },
                    {
                        id : 1981177,
                        name : "گیتار کلاسیک",
                        state : "canceled",
                        stateText : "لغو شده",
                        price : "4500000",
                        // کد پیگیری سفارش
                        trackingCode : "26500841",
                        img  : "/image/blogCover.jpg",
                    }
                ],
                totalPage : 2,
                loading : false,
            }
            setData(serverRes);
        });
    }
    function changePage(newPageNumber){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchUserOrders();
    }
    function getSvg(state){
        switch (state) {
            case "inProgress":
                return <svg className="stateIcon" stroke="currentColor" fill="#6cbdff" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>    
            case "complete":
                return <svg className="stateIcon" stroke="currentColor" fill="#2ab82a" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path></svg>
            case "canceled":
                return <svg className="stateIcon" stroke="currentColor" fill="#ff6347" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm86.63 272L320 342.63l-64-64-64 64L169.37 320l64-64-64-64L192 169.37l64 64 64-64L342.63 192l-64 64z"></path></svg>
            default:
                break;
        }
    }
    return <>
        {
            firstLoading ? <>
                <div className="flex flexCenter profileLoading2 rel">
                    <div className="profileSubLoadingSpin"></div>
                    <div className="flex flexCol userDesktopLoading">
                        <div className="flex flexCol rel userDesktopLeftDiv">
                            <div className="loaderItem"></div>
                        </div>
                    </div>        
                </div>
            </> :
            <>
                <div className="flex flexCol profileOrderList">
                    {
                        data && data.orderList && <>
                            {
                                data.orderList.length > 0 ?
                                <>
                                {
                                    data.orderList.map((item,index)=>{
                                        return <div className="flex flexCol profileOrderItem" key={index}>
                                            <div className="profileOrderItemHeader flex alignCenter justifyBetween">
                                                <p className={`faFont profileOrderItemName rel ${item.state}`}>
                                                    {
                                                        cutText(item.name,50)
                                                    }
                                                </p>
                                                <button className="flex flexCenter profileOrderSeeDetails faFont" onClick={()=>changeMenuHandler(`orders/${item.id}`)}>
                                                    <span>
                                                        مشاهده جزئیات
                                                    </span>
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                                                </button>
                                            </div>
                                            <div className="grid profileOrderDetailsDiv rel">
                                                <CustomImage data={{src:item.img, alt: item.name  ,class: "profileOrderItemImg" , height:80 , width : 80 ,}} />
                                                <div className="flex flexCol profileOrderInfoDiv">
                                                    <p className={`faFont profileOrderState flex alignCenter gap5 ${item.state}`}>
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
                                                        <span className="profileOrderSpanTitle">
                                                            وضعیت : 
                                                        </span>
                                                        <span>
                                                            {item.stateText}
                                                        </span>
                                                    </p>
                                                    <p className="faFont profileOrderPrice flex alignCenter gap5">
                                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M244.24,60a8,8,0,0,0-7.75-.4c-42.93,21-73.59,11.16-106,.78-34-10.89-69.25-22.14-117.95,1.64A8,8,0,0,0,8,69.24V189.17a8,8,0,0,0,11.51,7.19c42.93-21,73.59-11.16,106.05-.78,19.24,6.15,38.84,12.42,61,12.42,17.09,0,35.73-3.72,56.91-14.06a8,8,0,0,0,4.49-7.18V66.83A8,8,0,0,0,244.24,60ZM232,181.67c-40.6,18.17-70.25,8.69-101.56-1.32-19.24-6.15-38.84-12.42-61-12.42a122,122,0,0,0-45.4,9V74.33c40.6-18.17,70.25-8.69,101.56,1.32S189.14,96,232,79.09ZM128,96a32,32,0,1,0,32,32A32,32,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM56,96v48a8,8,0,0,1-16,0V96a8,8,0,1,1,16,0Zm144,64V112a8,8,0,1,1,16,0v48a8,8,0,1,1-16,0Z"></path></svg>
                                                        <span className="profileOrderSpanTitle">
                                                            مبلغ : 
                                                        </span>
                                                        <span>
                                                            {(+item.price).toLocaleString()}
                                                        </span>
                                                    </p>
                                                    <p className="faFont profileOrderTrackingCode flex alignCenter gap5">
                                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><rect width="5" height="5" x="3" y="3" rx="1"></rect><rect width="5" height="5" x="16" y="3" rx="1"></rect><rect width="5" height="5" x="3" y="16" rx="1"></rect><path d="M21 16h-3a2 2 0 0 0-2 2v3"></path><path d="M21 21v.01"></path><path d="M12 7v3a2 2 0 0 1-2 2H7"></path><path d="M3 12h.01"></path><path d="M12 3h.01"></path><path d="M12 16v.01"></path><path d="M16 12h1"></path><path d="M21 12v.01"></path><path d="M12 21v-1"></path></svg>
                                                        <span className="profileOrderSpanTitle">
                                                            کد سفارش :
                                                        </span>
                                                        <span>
                                                            {item.trackingCode}
                                                        </span>
                                                    </p>
                                                </div>
                                                {
                                                    getSvg(item.state)
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                                </>:
                                <>
                                    <EmptyResult linkAddres={"/store"} linkText={"برای مشاهده محصولات اینجا کلیک کنید"} text={"شما تاکنون خریدی از ما نداشته اید."} />
                                </>
                            }
                        </>
                    }
                </div>
                {
                    data.loading &&  <ProfileSubLoading/>
                }
                <PagePagintaion data={{totalNumber:data.totalPage, changePage : changePage, currentPage:pageNumber}} />
            </>
        }
    </>
}