import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import LogoText from "./LogoText";
import "../Style/videoItem.css";
import { AppContext } from '../App';

export default function VideoItem({data,index,hasPlay}){
    const {playerInfo} = useContext(AppContext);
    const [,setPlayer] = playerInfo;
    const videoItemVideo = useRef(null);
    const [isPlayed, setIsPlayed] = useState(false);
    const videoProgress = useRef(null);
    useEffect(() => {
        const parent = document.querySelector(".videoItemDiv"+index);
        videoProgress.current = parent.querySelector(".videoProgress");
    }, []);
    function playVideo(){
        if(!videoItemVideo.current){
            const parent = document.querySelector(".videoItemDiv"+index);
            videoItemVideo.current = parent.querySelector(".videoItemVideo");
            videoItemVideo.current.play();
            setIsPlayed(true);
        }else{
            videoItemVideo.current.play();
            setIsPlayed(true);
        }
        setPlayer(null);
    }
    function pauseVideo(){
        if(isPlayed){
            videoItemVideo.current.pause();
            setIsPlayed(false);
        }
    }
    function mouseInHandler(){
        if(videoItemVideo.current){
            videoItemVideo.current.play();
            setIsPlayed(true);
        }
    }
    function EndedHandler(){
        videoItemVideo.current.pause();
        setIsPlayed(false);
    }
    function updateTimeHandler(){
        const videoDuration = Math.round(videoItemVideo.current.duration);
        if (videoDuration > 0) {
            const timePassedPercent = Math.round(
                (100 * videoItemVideo.current.currentTime) / videoDuration
              );
              videoProgress.current.style.width = timePassedPercent + "%";
        }
    }
    return <div className={`flex flexCol videoItemDiv rel videoItemDiv${index}`}>
        {
            hasPlay ? <div className="flex flexCol rel videoParentItem"  onMouseEnter={()=>mouseInHandler()} onClick={()=>playVideo()} onMouseLeave={()=>pauseVideo()} onMouseOut={()=>pauseVideo()} >
                <video src={data.videoSrc} className="videoItemVideo" poster={data.poster} onEnded={()=>EndedHandler()} onTimeUpdate={()=>updateTimeHandler()}></video>
                {
                    !videoItemVideo.current ? !isPlayed && <div className="flex flexCenter playLogo">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg>
                    </div> : !isPlayed && <div className="flex flexCenter playLogo">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M208,48V208a8,8,0,0,1-8,8H160a8,8,0,0,1-8-8V48a8,8,0,0,1,8-8h40A8,8,0,0,1,208,48ZM96,40H56a8,8,0,0,0-8,8V208a8,8,0,0,0,8,8H96a8,8,0,0,0,8-8V48A8,8,0,0,0,96,40Z"></path><path d="M200,32H160a16,16,0,0,0-16,16V208a16,16,0,0,0,16,16h40a16,16,0,0,0,16-16V48A16,16,0,0,0,200,32Zm0,176H160V48h40ZM96,32H56A16,16,0,0,0,40,48V208a16,16,0,0,0,16,16H96a16,16,0,0,0,16-16V48A16,16,0,0,0,96,32Zm0,176H56V48H96Z"></path></svg>
                    </div>
                }
                <div className="flex flexCol videoProgressHolder">
                    <div className="videoProgress"></div>
                </div>
            </div>
            :
            <div className="flex flexCol rel videoParentItem">
                <Link className="videoImgDiv flex flexCol" to={data.videoLink}>
                    <img src={data.poster} alt={data.title} className="videoImg" draggable="false" />
                </Link>
            </div>
        }
        
        <div className="flex flexCol videoInfoDiv rel">
            <img src={data.teacherImg} alt={data.teacherName} className="teacherImg" />
            <Link to={data.videoLink} className="faFont textSize11 textClr3 videoName">
                {
                    data.videoTitle
                }
            </Link>
            <Link to={data.teacherLink} className="faFont textSize8 textClr5 teacherName">
                {
                    data.teacherName
                }
            </Link>
            <div className="flex alignCenter gap5 itemPriceDiv rel">
                {
                     +data.price !== 0 && +data.discount !== 0 && data.discount !== "" && <p className="faFont videoItemDiscount flex alignCenter">
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M109.25 173.25c24.99-24.99 24.99-65.52 0-90.51-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 25 25 65.52 25 90.51 0zm256 165.49c-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 24.99 24.99 65.52 24.99 90.51 0 25-24.99 25-65.51 0-90.51zm-1.94-231.43l-22.62-22.62c-12.5-12.5-32.76-12.5-45.25 0L20.69 359.44c-12.5 12.5-12.5 32.76 0 45.25l22.62 22.62c12.5 12.5 32.76 12.5 45.25 0l274.75-274.75c12.5-12.49 12.5-32.75 0-45.25z"></path></svg>
                    {
                        data.discount
                    }
                    </p>
                }
                {
                    +data.discount !== 0 && data.discount && <p className="faFont priceBeforeOff">
                    {
                        (+data.price).toLocaleString()
                    }
                    </p>
                }
                <p className="faFont courseItemPrice">
                    {
                        +data.price !== 0 ? 
                            +data.discount !== 0 && data.discount !== "" ? 
                                (+data.price - ((+data.price * +data.discount) / 100)).toLocaleString() + " تومان"
                            : 
                            (+data.price).toLocaleString() + " تومان"
                        : "رایگان"
                    }
                </p>
            </div>
        </div>
        <div className="flex alignCenter justifyBetween videoItemFooter">
            <LogoText data={{logo : <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="10" x2="14" y1="2" y2="2"></line><line x1="12" x2="15" y1="14" y2="11"></line><circle cx="12" cy="14" r="8"></circle></svg>,
                text : data.duration
            }} />
            <LogoText data={{logo : <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>,
                text : data.visit
            }} />
        </div>
    </div>
}
