import React, { useEffect, useRef, useState }  from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { scrollToTop } from "./Methods";
import "../Style/allPodcasts.css";
import NavsList from "./NavsList";
import PagePagintaion from "./PagePagination";
import FullBanner from "./FullBanner";
import PodcastItem from "./PodcastItem";

export default function  AllPodcasts(){
    const navigate = useNavigate();
    const [data,setData] = useState({loading : false});
    const pageNumber = useRef(1);
    const sort = useRef("");
    const cat = useRef("");
    const tag = useRef("");
    const [showFilterList,setShowFilterList] = useState(false);
    const [searchParam,] = useSearchParams();
    const firstLoad = useRef(true);
    const [firstLoading,setFirstLoading] = useState(true);
    const [activeTags,setActiveTags] = useState({
            tag : searchParam.get("tag"),
            cat : searchParam.get("cat")
        });
    const isSearchParamBaseQuery = useRef(true);
    useEffect(()=>{
        document.title = "پادکست ها - با ما می رسی";
        scrollToTop();
        if(firstLoad.current){
            firstLoad.current = false;
            fetchData();
        }
    },[]);
    function fetchData(){
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        tag.current = searchParam.get("tag") ? searchParam.get("tag") : "";
        cat.current = searchParam.get("cat") ? searchParam.get("cat") : "";
        sort.current = searchParam.get("sort") ? searchParam.get("sort") : "default";
        const data = new FormData();
        data.append("PageCategoryID", "1");
        data.append("pageCount", 10);
        data.append("pageIndex", pageNumber.current);
        data.append("sort",sort.current);
        data.append("category",cat.current);
        data.append("tag",tag.current);
        fetch("https://crm.bamamiresi.com/Enrollment/GetAllPodcasts",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            setFirstLoading(false);
            const serverData = json[0];
            if (serverData.code !== 200) {
                throw new Error("Error happend");
            }
            setData(
                {
                    loading : false,
                    resultList: serverData.blogs[0].post,
                    tags : serverData.tags,
                    totalPage : serverData.cnt,
                    suggestCat : serverData.category 
                }
            );
            if(isSearchParamBaseQuery.current){
                isSearchParamBaseQuery.current = false;
                if(searchParam.size > 0){
                    let tempActiveCat = serverData.category.find(catItem=> catItem.latinName === activeTags.cat);
                    let tempActiveTag = serverData.tags.find(tagItem=> tagItem.latinName === activeTags.tag);
                    if(tempActiveCat){
                        tempActiveCat = tempActiveCat.name;
                    }else{
                        searchParam.delete("cat");
                        tempActiveCat = "";
                        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
                    }
    
                    if(tempActiveTag){
                        tempActiveTag = tempActiveTag.name ;
                    }else{
                        searchParam.delete("tag");
                        tempActiveTag = "";
                        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
                    }
                    setActiveTags({
                        cat :  tempActiveCat ,
                        tag : tempActiveTag
                    });
                }
            }
        })
        .catch((err) => {
            console.log(err);
            navigate("/505");
        });
    }
    function changePage(newPageNumber){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeTag(tagItem){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        setActiveTags({...activeTags,tag:tagItem.name});
        searchParam.set("tag",tagItem.latinName);
        searchParam.delete("p");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeCat(catItem){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        setActiveTags({...activeTags,cat:catItem.name});
        searchParam.set("cat",catItem.latinName);
        searchParam.delete("p");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function removeTag(){
        setActiveTags({...activeTags,"tag":""});
        searchParam.delete("tag");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
    }
    function removeCat(){
        setActiveTags({...activeTags,"cat":""});
        searchParam.delete("cat");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
    }
    return <>
        <NavsList data={[{name:"پادکست ها",link:"/podcasts"}]} />
        {
            ( activeTags.cat || activeTags.tag ) &&
            <div className="flex alignCenter activeTagList container">
            {
                activeTags.cat && <button className="showActiveTagBtn faFont flex alignCenter gap5 faFont textSize9 textClr3 suggestTagItem" onClick={()=>removeCat()}>
                    {
                        activeTags.cat
                    }
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                </button>
            }
            {
                activeTags.tag && <button className="showActiveTagBtn faFont flex alignCenter gap5 faFont textSize9 textClr3 suggestTagItem" onClick={()=>removeTag()}>
                    {
                        activeTags.tag
                    }
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                </button>
            }
            </div>
        }
        <main className="grid mainGrid container" onClick={()=>{showFilterList && setShowFilterList(false)}}>
            {
                firstLoading ? <div className="grid courseFilterLoading">
                    <div className="searchInputLoading loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol filterLoadingBtn loadingStoryItem">
                        <div className="loaderItem"></div>
                    </div>
                </div> :
                <div className="grid mainGridRightDiv">
                    <form action="/search" className="flex flexCol rightSideSearchDiv rel">
                        <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="جستجو در مقالات"/>
                        <button className="flex flexCenter courseSeachSubmitBtn">
                            <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                            <span className="hideText">search</span>
                        </button>
                    </form>
                    <div className="flex flexCol filterSection">
                        <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                            <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                            <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                        </button>
                        <div className={showFilterList ? "flex flexCol filterListDiv active customScrollbar" : "filterListDiv customScrollbar"}>
                            {
                                data.tags && data.tags.length > 0 && <div className="filterRow flex flexCol" >
                                    <h4 className="faFont suggestCatTitle textSize1 textClr5">
                                        تگ های پربازدید:
                                    </h4>
                                    <div className="flex tagsList">
                                        {
                                            data.tags.map((item,index)=>{
                                                return <button className={`faFont textSize9 textClr3 flex alignCenter suggestTagItem ${activeTags.tag === item.name ? "activeSuggestTagItem" : ""}`} key={index} onClick={()=>changeTag(item)}>
                                                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path></svg>
                                                    {
                                                        item.name
                                                    }
                                                </button>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                            {
                                data.suggestCat && data.suggestCat.length > 0 && 
                                <div className="filterRow flex flexCol" >
                                    <h4 className="faFont suggestCatTitle textSize1 textClr5">
                                        دسته بندی های :
                                    </h4>
                                    <div className="flex flexCol suggestList">
                                        {
                                            data.suggestCat.map((item,index)=>{
                                                return <button className={`faFont textSize9 textClr3 flex alignCenter justifyBetween suggestCatItem ${activeTags.cat === item.name ? "activeSuggestTagItem" : ""}`} key={index} onClick={()=>changeCat(item)}>
                                                    {
                                                        item.name
                                                    }
                                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
                                                </button>
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                data.resultList ? <div className="flex flexCol rel courseSectionLeftDiv">
                    <div className="podcastGrid grid">
                        {
                            data.resultList.map((item,index)=>{
                                return <PodcastItem data={item} key={index}/>
                            })
                        }
                    </div>
                    <PagePagintaion data={{totalNumber:data.totalPage,
                        changePage : changePage, currentPage:pageNumber}} />
                    {
                        firstLoading.data && <div className="flex flexCol loadingMoreData container">
                            <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                            </div>
                        </div>
                    }
                </div>
                : <div className="grid allCastsLoading">
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="flex flexCol rel loadingStoryItem allCastsLoadingItem">
                        <div className="loaderItem"></div>
                    </div>
                </div>
            }
        </main>
        {data.middleBanner && <FullBanner data={data.middleBanner} />}
    </>
}