import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Style/header.css";
import { AppContext } from "../App";
import Cookies from "js-cookie";
import HeaderCartDetails from "./HeaderCartDetails";
export default function Header() {
  const [showNav, setShowNav] = useState(false);
  const [toggleExpandList, setToggleExpandList] = useState(false);
  const [toggleExpandSchools, setToggleExpandSchool] = useState(false);
  const [subToggleMenu, setSubToggleMenu] = useState({});
  const [toggleProductList, setToggleProductList] = useState(false);
  const [subProductToggleMenu, setSubProductToggleMenu] = useState({});
  const {
    userData: [userInfo, setUserInfo],
    siteInfo: [, setSiteData],
    cartContext: [, setCarData],
  } = useContext(AppContext);
  const [data, setData] = useState({
    socialMedia: null,
    categories: null,
    phone1: "",
  });
  const navigate = useNavigate();
  let preLink = "https://web.bamamiresi.com/";
  const firstLoad = useRef(true);
  const headerCat = useRef([]);
  const mobileCat = useRef([]);
  const productMobCat = useRef([]);
  const productDeskCat = useRef([]);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      const data = new FormData();
      data.append("token", userInfo.tkn ? userInfo.tkn : -1);
      // data.append("token", "7b577d9f-e7fa-40c9-832e-68a272103317");
      fetch("https://crm.bamamiresi.com/Enrollment/getheader", {
        method: "POST",
        body: data,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("102");
          }
          return res.json();
        })
        .then((json) => {
          if (json.error) {
            throw new Error(json.msg);
          }
          const serverRes = json[0];
          // save user data
          let newToken = userInfo.tkn ? userInfo.tkn : serverRes.token;
          if (!Cookies.get("tkn")) {
            Cookies.set("tkn", newToken, {
              expires: 60,
              path: "/",
              secure: true,
              sameSite: "strict",
            });
          }
          setUserInfo({ ...userInfo, data: serverRes.user[0], tkn: newToken });
          // save response for header elements
          setData(json[0]);
          // set data for footer
          setSiteData({
            address: serverRes.Address,
            email: serverRes.email,
            phone1: serverRes.phone1,
            social: serverRes.socialNetwork,
            about: serverRes.aboutus,
            user: serverRes.user[0],
            productsCat: serverRes.productCategory,
          });
          // create category links
          let tempToggleMenu = {};
          serverRes.category.map((item) => {
            let tempArr = [];
            let mobileTemp = [];
            item.subcategory.map((sub, index) => {
              tempArr.push(
                <Link
                  to={`/course/${sub.latinName}`}
                  key={index}
                  className="faFont headerDropSubLink flex textSize9 textClr5"
                >
                  {sub.name}
                </Link>
              );
              mobileTemp.push(
                <Link
                  to={`/course/${sub.latinName}`}
                  key={index}
                  className="faFont navlinkItem flex textSize9 textClr3"
                >
                  {sub.name}
                </Link>
              );
            });
            tempToggleMenu[item.id] = false;
            headerCat.current.push(tempArr);
            mobileCat.current.push(mobileTemp);
          });
          setSubToggleMenu(tempToggleMenu);
          let tempSubToggleMenu = {};
          serverRes.productCategory.map((item) => {
            let tempArr = [];
            let mobileTemp = [];
            item.subcategory.map((sub, index) => {
              tempArr.push(
                <Link
                  to={`/products/${item.id}/${sub.id}`}
                  key={index}
                  className="faFont headerDropSubLink flex textSize9 textClr5"
                >
                  {sub.name}
                </Link>
              );
              mobileTemp.push(
                <Link
                  to={`/products/${item.id}/${sub.id}`}
                  key={index}
                  className="faFont navlinkItem flex textSize9 textClr3"
                >
                  {sub.name}
                </Link>
              );
            });
            tempSubToggleMenu[item.id] = false;
            productDeskCat.current.push(tempArr);
            productMobCat.current.push(mobileTemp);
          });
          setSubProductToggleMenu(tempSubToggleMenu);
        })
        .catch((err) => {});
    }
  }, []);
  useEffect(() => {
    setShowNav(false);
  }, [navigate]);
  return (
    <>
      {data.minTopImage && (
        <a
          href={data.topImageUrl}
          className="flex flexCol headerTopLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={preLink + data.topImage}
            alt="headerImg"
            className="headerTopLargeImage"
          />
          <img
            src={preLink + data.minTopImage}
            alt="headerImg"
            className="headerTopMinImage"
          />
        </a>
      )}
      <div className="flex flexCol headerCallDiv">
        <div className="flex alignCenter container headerCallContainer">
          <p className="faFont textSize1 textClr3 addressHeaderText flex alignCenter gap10">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 14c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4zm0-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z"></path>
              <path d="M11.42 21.814a.998.998 0 0 0 1.16 0C12.884 21.599 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.995c-.029 6.445 7.116 11.604 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.005.021 4.438-4.388 8.423-6 9.73-1.611-1.308-6.021-5.294-6-9.735 0-3.309 2.691-6 6-6z"></path>
            </svg>
            آدرس : {data.Address ? data.Address : ""}
          </p>
          <div className="flex alginCenter headerCallLeftDiv">
            <a
              href={"tel:+" + data.phone1}
              className="headerCallItem flex alignCenter faFont textSize1 textClr5"
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                ></path>
              </svg>
              <span>{data.phone1}</span>
            </a>
          </div>
          <form action="/search" className="flex flexCol mobileSearchDiv rel">
            <input
              type="text"
              name="q"
              className="faFont textSize1 textClr3 mobileSearchInput"
              placeholder="جستجو کنید ..."
              required
            />
            <button className="flex flexCenter navSearchIcon textClr5 textSize1">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
      <header className="flex flexCol">
        <div className="flex alignCenter justifyBetween flexMainDiv container rel">
          <Link
            className="faFont textSize12 textClr3 flex alignCenter headerMainLogo"
            to="/"
          >
            <img
              src="/image/headerLogo.webp"
              alt="bamamiresi"
              className="headerMainLogoImg"
              height={40}
            />
          </Link>
          <div className="flex alignCenter headerDeskDiv">
            <div className="flex flexCol headerDropDiv">
              <p className="faFont headerDeskLink textClr3 textSize8 flex alignCenter">
                آموزشگاه ها
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                </svg>
              </p>
              <div className="flex flexCol headerDropSubMainDiv">
                <div className="flex flexCol headerDropSubDiv rel">
                  <div className="flex flexCol schoolsList">
                    {data.academy &&
                      data.academy.length > 0 &&
                      data.academy.map((item, index) => {
                        return (
                          <Link
                            to={`/school/${item.id}`}
                            className="faFont headerDropSubLink flex textSize9 textClr5"
                            key={index}
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    <Link
                      to={`/schools`}
                      className="faFont headerDropSubLink flex textSize9 textClr5"
                    >
                      مشاهده همه ی آموزشگاه ها
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <Link
              to="/teachers"
              className="faFont headerDeskLink textClr3 textSize8"
            >
              اساتید
            </Link>
            <div className="flex flexCol headerDropDiv">
              <Link
                to="/courses"
                className="faFont headerDeskLink textClr3 textSize8 flex alignCenter"
              >
                دوره ها
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                </svg>
              </Link>
              <div className="flex flexCol headerDropSubMainDiv">
                <div className="flex flexCol headerDropSubDiv rel">
                  {data.category &&
                    data.category.map((item, index) => {
                      return (
                        <div
                          className={`flex flexCol headerItemParentDiv ${
                            index === 0 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <button className="faFont headerDropShowMenuBtn textSize9 textClr3 flex alignCenter spaceBetween">
                            {item.name}
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                            </svg>
                          </button>
                          <div className="headerMegaMenuLeftDiv grid row3">
                            <div className="flex flexCol headerMegaMenuRow">
                              <div className="flex flexCol headerMegaMenuSubRow">
                                {headerCat.current[index]}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flexCol headerDropDiv">
              <button className="faFont headerDeskLink textClr3 textSize8 flex alignCenter">
                محصولات
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                </svg>
              </button>
              <div className="flex flexCol headerDropSubMainDiv">
                <div className="flex flexCol headerDropSubDiv rel">
                  {data.productCategory &&
                    data.productCategory.map((item, index) => {
                      return (
                        <div
                          className={`flex flexCol headerItemParentDiv ${
                            index === 0 ? "active" : ""
                          }`}
                          key={index}
                        >
                          <Link
                            to={`/products/${item.id}`}
                            className="faFont headerDropShowMenuBtn textSize9 textClr3 flex alignCenter spaceBetween"
                          >
                            {item.name}
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                            </svg>
                          </Link>
                          <div className="headerMegaMenuLeftDiv grid row3">
                            <div className="flex flexCol headerMegaMenuRow">
                              <div className="flex flexCol headerMegaMenuSubRow">
                                {productDeskCat.current[index]}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <Link
              to="/blogs"
              className="faFont headerDeskLink textClr3 textSize8"
            >
              مقالات
            </Link>
            <Link
              to="/aboutus"
              className="faFont headerDeskLink textClr3 textSize8"
            >
              درباره ما
            </Link>
            <Link
              to="/contactus"
              className="faFont headerDeskLink textClr3 textSize8"
            >
              تماس با ما
            </Link>
          </div>
          <div className="flex alignCenter gap10 headerRightDiv">
            <div className="flex flexCol rel cartMainHoverDiv">
              <Link className="cartBtn flex flexCenter" to="cart">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z"></path>
                  <path d="M3 6h18"></path>
                  <path d="M16 10a4 4 0 0 1-8 0"></path>
                </svg>
                <span className="hideText">cart</span>
              </Link>
              <HeaderCartDetails />
            </div>
            {userInfo.data ? (
              <div className="rel userBtnDiv flex flexCol">
                <Link className="userBtn flex flexCenter" to="profile">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="8" r="5"></circle>
                    <path d="M20 21a8 8 0 1 0-16 0"></path>
                  </svg>
                  <span className="hideText">user</span>
                </Link>
              </div>
            ) : (
              <Link className="loginBtn flex flexCenter" to="login">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                  <path d="M21 12h-13l3 -3"></path>
                  <path d="M11 15l-3 -3"></path>
                </svg>
                <span className="faFont textSize9 textClr3">
                  ورود | ثبت نام
                </span>
              </Link>
            )}
            <button
              className="flex flexCenter menuBtn"
              onClick={() => setShowNav(true)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 4H21V6H3V4ZM3 11H21V13H3V11ZM3 18H21V20H3V18Z"></path>
              </svg>
              <span className="hideText">menu</span>
            </button>
          </div>
        </div>
      </header>
      <div
        className={showNav ? "navCloserDiv active" : "navCloserDiv"}
        onClick={() => setShowNav(false)}
      >
        <button className="flex flexCenter navCloseBtn">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
          </svg>
        </button>
      </div>
      <nav className={showNav ? "nav active grid" : "nav"}>
        <div className="flex flexCol navLogoDiv">
          <Link className="faFont textSize12 textClr3 flex alignCenter" to="/">
            <img
              src="/image/headerLogo.webp"
              alt="bamamiresi"
              className="navLogo"
              height={40}
            />
          </Link>
          <form action="/search" className="flex flexCol mobileSearchDiv rel">
            <input
              type="text"
              name="q"
              className="faFont textSize1 textClr3 mobileSearchInput"
              placeholder="جستجو کنید ..."
              required
            />
            <button className="flex flexCenter navSearchIcon textClr5 textSize1">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
            </button>
          </form>
        </div>
        <div className="flex flexCol navLinkList">
          <Link to="" className="faFont navlinkItem flex textSize1 textClr3">
            صفحه اصلی
          </Link>
          <div className="flex flexCol rel expandParentDiv">
            <p className="faFont navlinkItem flex textSize1 textClr3">
              آموزشگاه ها
            </p>
            <button
              className={
                toggleExpandSchools
                  ? "showSubListBtn flex flexCenter textClr3 toggle"
                  : "showSubListBtn flex flexCenter textClr3"
              }
              onClick={() => setToggleExpandSchool(!toggleExpandSchools)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
              </svg>
            </button>
            <div className="flex flexCol expandList customScrollbar">
              <div className="flex flexCol expandSubList">
                <div className="flex flexCol mobileExpandParentDiv2">
                  <div className="flex flexCol rel firstSubList">
                    {data.academy &&
                      data.academy.length > 0 &&
                      data.academy.map((item, index) => {
                        return (
                          <Link
                            to={`/school/${item.id}`}
                            className="faFont navlinkItem flex textSize9 textClr3"
                            key={index}
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    <Link
                      to={`/schools`}
                      className="faFont navlinkItem flex textSize9 textClr3"
                    >
                      مشاهده همه ی آموزشگاه ها
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/teachers"
            className="faFont navlinkItem flex textSize1 textClr3"
          >
            اساتید
          </Link>
          <div className="flex flexCol rel expandParentDiv">
            <Link
              to="/courses"
              className="faFont navlinkItem flex textSize1 textClr3"
            >
              دوره ها
            </Link>
            <button
              className={
                toggleExpandList
                  ? "showSubListBtn flex flexCenter textClr3 toggle"
                  : "showSubListBtn flex flexCenter textClr3"
              }
              onClick={() => setToggleExpandList(!toggleExpandList)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
              </svg>
            </button>
            <div className="flex flexCol expandList customScrollbar">
              <div className="flex flexCol expandSubList">
                <div className="flex flexCol mobileExpandParentDiv2">
                  {data.category &&
                    data.category.map((item, index) => {
                      return (
                        <div
                          className="flex flexCol rel firstSubList"
                          key={index}
                        >
                          <button className="faFont navlinkItem flex textSize9 textClr3">
                            {item.name}
                          </button>
                          <button
                            className={
                              subToggleMenu[item.id]
                                ? "subToggleBtn flex flexCenter textClr3 toggle"
                                : "subToggleBtn flex flexCenter textClr3"
                            }
                            onClick={() =>
                              setSubToggleMenu({
                                ...subToggleMenu,
                                [item.id]: !subToggleMenu[item.id],
                              })
                            }
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                            </svg>
                          </button>
                          <div className="flex flexCol rel secendSubList">
                            <div className="flex flexCol">
                              {mobileCat.current[index]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flexCol rel expandParentDiv">
            <button className="faFont navlinkItem flex textSize1 textClr3">
              محصولات
            </button>
            <button
              className={
                toggleProductList
                  ? "showSubListBtn flex flexCenter textClr3 toggle"
                  : "showSubListBtn flex flexCenter textClr3"
              }
              onClick={() => setToggleProductList(!toggleProductList)}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
              </svg>
            </button>
            <div className="flex flexCol expandList customScrollbar">
              <div className="flex flexCol expandSubList">
                <div className="flex flexCol mobileExpandParentDiv2">
                  {data.category &&
                    data.category.map((item, index) => {
                      return (
                        <div
                          className="flex flexCol rel firstSubList"
                          key={index}
                        >
                          <Link
                            to={`/products/${item.id}`}
                            className="faFont navlinkItem flex textSize9 textClr3"
                          >
                            {item.name}
                          </Link>
                          <button
                            className={
                              subProductToggleMenu[item.id]
                                ? "subToggleBtn flex flexCenter textClr3 toggle"
                                : "subToggleBtn flex flexCenter textClr3"
                            }
                            onClick={() =>
                              setSubProductToggleMenu({
                                ...subProductToggleMenu,
                                [item.id]: !subProductToggleMenu[item.id],
                              })
                            }
                          >
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                            </svg>
                          </button>
                          <div className="flex flexCol rel secendSubList">
                            <div className="flex flexCol">
                              {productMobCat.current[index]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/blogs"
            className="faFont navlinkItem flex textSize1 textClr3"
          >
            مقالات
          </Link>
          <Link
            to="/aboutus"
            className="faFont navlinkItem flex textSize1 textClr3"
          >
            درباره ما
          </Link>
          <Link
            to="/contactus"
            className="faFont navlinkItem flex textSize1 textClr3"
          >
            تماس با ما
          </Link>
        </div>
        <div className="flex alignCenter navFooterDiv flexCenter">
          <a href="" className="flex flexCenter navSocialItem textClr3">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M349.33 69.33a93.62 93.62 0 0 1 93.34 93.34v186.66a93.62 93.62 0 0 1-93.34 93.34H162.67a93.62 93.62 0 0 1-93.34-93.34V162.67a93.62 93.62 0 0 1 93.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"></path>
              <path d="M377.33 162.67a28 28 0 1 1 28-28 27.94 27.94 0 0 1-28 28zM256 181.33A74.67 74.67 0 1 1 181.33 256 74.75 74.75 0 0 1 256 181.33m0-37.33a112 112 0 1 0 112 112 112 112 0 0 0-112-112z"></path>
            </svg>
            <span className="hideText">instagram</span>
          </a>
          <a href="" className="flex flexCenter navSocialItem textClr3">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path>
            </svg>
            <span className="hideText">telegram</span>
          </a>
        </div>
      </nav>
    </>
  );
}
