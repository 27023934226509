import React from "react";
import { Link } from "react-router-dom";
export default function EmptyResult({text,linkText,linkAddres}){
    return <div className="flex flexCol flexCenter emptyOrderList">
        <object data="/icon/sad.svg" type="image/svg+xml" width="120" height="120" className="profileEmptyImg">
            Your browser does not support SVGs.
        </object>
        <p className="faFont profileEmptyText">
            {
                text
            }
        </p>
        <Link to={linkAddres} className="emptyLink faFont flex alignCenter" draggable="false">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"></path><path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"></path></svg>
            {
                linkText
            }
        </Link>
    </div>
}