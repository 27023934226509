import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../Style/profile.css";
import ProfileClasses from "./ProfileClasses";
import { AppContext } from "../App";
import NavsList from "./NavsList";
import ProfileHome from "./ProfileHome";
import ProfileOrders from "./ProfileOrders";
import ProfileOrderDetails from "./ProfileOrderDetails";
import ProfileVideos from "./ProfileVideos";
import ProfileUpload from "./ProfileUpload";
import ProfileAddAddress from "./ProfileAddAdress";

export default function Profile() {
  const navigate = useNavigate();
  const { siteInfo } = useContext(AppContext);
  const [siteData] = siteInfo;
  const params = useParams();
  const [activePath, setActivePath] = useState(
    params.path ? params.path : "home"
  );
  const [userDataLoading, setUserDataLoading] = useState(true);

  useEffect(() => {
    setActivePath(params.path ? params.path : "home");
  }, [navigate]);
  useEffect(() => {
    if (siteData) {
      if (siteData.user) {
        setUserDataLoading(false);
      } else {
        setUserDataLoading(false);
        navigate("/login");
      }
    }
  }, [siteData, navigate]);

  function changeMenuHandler(newMenu, subMenu) {
    if (newMenu === activePath && subMenu === params.id) {
      return;
    }
    setActivePath(newMenu);
    navigate(`/profile/${newMenu}`);
  }
  function logOutHandler() {}
  function getHomeRightHeader() {
    return (
      <>
        <div className="flex alignCenter justifyBetween profileHomeHeaderDiv">
          <div className="faFont profileUserInfoDiv flex alignCenter">
            <span className="profileUserLogo flex flexCenter">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 448 512"
                height="16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"></path>
              </svg>
            </span>
            <div className="flex flexCol">
              <p className="profileUserName faFont">
                {siteData.user.firstName + " " + siteData.user.lastName}
              </p>
              <p className="faFont userPhoneNumber">{"09123456789"}</p>
            </div>
          </div>
          <button
            className="faFont editUserDataBtn flex flexCenter"
            onClick={() => changeMenuHandler("editUserInfo")}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="15"
              width="15"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z"></path>
            </svg>
            <span>ویرایش اطلاعات</span>
          </button>
        </div>
        <div className="flex flexCol profileRightNavList">
          {getProfileNavs()}
        </div>
      </>
    );
  }
  function getProfileNavs() {
    return (
      <>
        <button
          onClick={() => changeMenuHandler("classes")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "classes" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12" cy="8" r="5"></circle>
              <path d="M20 21a8 8 0 0 0-16 0"></path>
            </svg>
            کلاس های من
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => changeMenuHandler("orders")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "orders" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 6H15C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6ZM7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6H20C20.5523 6 21 6.44772 21 7V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V7C3 6.44772 3.44772 6 4 6H7ZM5 8V20H19V8H5ZM9 10C9 11.6569 10.3431 13 12 13C13.6569 13 15 11.6569 15 10H17C17 12.7614 14.7614 15 12 15C9.23858 15 7 12.7614 7 10H9Z"></path>
            </svg>
            سفارش ها
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => changeMenuHandler("videos")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "videos" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"></path>
              <rect x="2" y="6" width="14" height="12" rx="2"></rect>
            </svg>
            ویدیوهای من
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => changeMenuHandler("comments")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "comments" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17 9H7V7H17V9Z" fill="currentColor"></path>
              <path d="M7 13H17V11H7V13Z" fill="currentColor"></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 18V2H22V18H16V22H14C11.7909 22 10 20.2091 10 18H2ZM12 16V18C12 19.1046 12.8954 20 14 20V16H20V4H4V16H12Z"
                fill="currentColor"
              ></path>
            </svg>
            نظرات من
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => changeMenuHandler("history")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "history" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 20.777a8.942 8.942 0 0 1 -2.48 -.969"></path>
              <path d="M14 3.223a9.003 9.003 0 0 1 0 17.554"></path>
              <path d="M4.579 17.093a8.961 8.961 0 0 1 -1.227 -2.592"></path>
              <path d="M3.124 10.5c.16 -.95 .468 -1.85 .9 -2.675l.169 -.305"></path>
              <path d="M6.907 4.579a8.954 8.954 0 0 1 3.093 -1.356"></path>
              <path d="M12 8v4l3 3"></path>
            </svg>
            بازدیدهای اخیر
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => changeMenuHandler("editUserInfo")}
          className={`faFont profileNavItem flex alignCenter justifyBetween ${
            activePath === "editUserInfo" ? "active" : ""
          }`}
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.24264 18.9967H21V20.9967H3V16.754L12.8995 6.85453L17.1421 11.0972L9.24264 18.9967ZM14.3137 5.44032L16.435 3.319C16.8256 2.92848 17.4587 2.92848 17.8492 3.319L20.6777 6.14743C21.0682 6.53795 21.0682 7.17112 20.6777 7.56164L18.5563 9.68296L14.3137 5.44032Z"></path>
            </svg>
            ویرایش اطلاعات
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <button
          onClick={() => logOutHandler()}
          className="faFont profileNavItem flex alignCenter justifyBetween"
        >
          <p className="flex alignCenter gap5">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="18"
              width="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M215.469 332.802l29.863 29.864L352 256 245.332 149.333l-29.863 29.865 55.469 55.469H64v42.666h205.864l-54.395 55.469zM405.334 64H106.666C83.198 64 64 83.198 64 106.666V192h42.666v-85.333h298.668v298.668H106.666V320H64v85.334C64 428.802 83.198 448 106.666 448h298.668C428.802 448 448 428.802 448 405.334V106.666C448 83.198 428.802 64 405.334 64z"></path>
            </svg>
            خروج از حساب کاربری
          </p>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="15"
            width="15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
      </>
    );
  }
  function getNavList() {
    if (params.id && params.path === "orders") {
      return (
        <NavsList
          data={[
            { name: "پروفایل کاربر", link: "/profile" },
            { name: "سفارش‌ها", link: "/profile/orders" },
            { name: "جزئیات سفارش", link: "/profile/orders/details" },
          ]}
        />
      );
    }
    const currentPagePath = params.path ? params.path : "home";
    switch (currentPagePath) {
      case "home":
        return (
          <div className="profileHomePageNavDiv">
            <NavsList data={[{ name: "پروفایل کاربر", link: "/profile" }]} />
          </div>
        );
      case "classes":
        return (
          <NavsList
            data={[
              { name: "پروفایل کاربر", link: "/profile" },
              { name: "کلاس های من", link: "/profile/classes" },
            ]}
          />
        );
      case "orders":
        return (
          <NavsList
            data={[
              { name: "پروفایل کاربر", link: "/profile" },
              { name: "سفارش ها", link: "/profile/orders" },
            ]}
          />
        );
      case "videos":
        return (
          <NavsList
            data={[
              { name: "پروفایل کاربر", link: "/profile" },
              { name: "ویدیوها", link: "/profile/videos" },
            ]}
          />
        );
      case "upload":
        return (
          <NavsList
            data={[
              { name: "پروفایل کاربر", link: "/profile" },
              { name: "آپلود ویدیو", link: "/profile/upload" },
            ]}
          />
        );
      case "addAddress":
        return (
          <NavsList
            data={[
              { name: "پروفایل کاربر", link: "/profile" },
              { name: "آدرس های من", link: "/profile/addresses" },
              { name: "افزودن آدرس", link: "/profile/addAddress" },
            ]}
          />
        );
      default:
        break;
    }
  }
  function getProfileContentBaseUrl() {
    if (params.id && params.path === "orders") {
      return <ProfileOrderDetails changeMenuHandler={changeMenuHandler} />;
    }
    switch (params.path) {
      case "classes":
        return <ProfileClasses />;
      case "orders":
        return <ProfileOrders changeMenuHandler={changeMenuHandler} />;
      case "videos":
        return <ProfileVideos changeMenuHandler={changeMenuHandler} />;
      case "upload":
        return <ProfileUpload changeMenuHandler={changeMenuHandler} />;
      case "addAddress":
        return <ProfileAddAddress changeMenuHandler={changeMenuHandler} />;
      default:
        return (
          <div className="profileHomePageNavDiv">
            <ProfileHome
              changeMenuHandler={changeMenuHandler}
              getProfileNavs={getProfileNavs}
            />
          </div>
        );
    }
  }
  return (
    <>
      {getNavList()}
      {userDataLoading ? (
        <>
          <div className="flex flexCenter userLoading rel container">
            <div className="profileSubLoadingSpin"></div>
            <div className="grid userDesktopLoading">
              <div className="flex flexCol rel userDesktopRightDiv">
                <div className="loaderItem"></div>
              </div>
              <div className="flex flexCol rel userDesktopLeftDiv">
                <div className="loaderItem"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <main className="grid profieMainGrid container">
            <section
              className={`flex flexCol profileRightSection ${
                params.path ? "subPage" : ""
              } `}
            >
              {getHomeRightHeader()}
            </section>
            <section className="flex flexCol profileLeftSection">
              {getProfileContentBaseUrl()}
            </section>
          </main>
        </>
      )}
    </>
  );
  // return <>
  // {
  //     isLoading ? <></> :
  //     <section className="profileMainGrid grid container ">
  //         <div className="profileLinkList flex flexCol">
  //             <button onClick={()=>changeMenuHandler("editUserInfo")} className={`profileLinkItem faFont textSize9 textClr3 flex alignCenter ${activeMenu === "editUserInfo" ? "active" : ""}`}>
  //                 ویرایش اطلاعات
  //             </button>
  //             <button onClick={()=>changeMenuHandler("myClasses")} className={`profileLinkItem faFont textSize9 textClr3 flex alignCenter ${activeMenu === "myClasses" ? "active" : ""}`}>
  //                کلاس های من
  //             </button>
  //         </div>
  //         <div className="flex flexCol profileDetailsDiv rel">
  //             {
  //                 activeMenu === "home" && <div className="profileHomeNavList flex flexCol">
  //                     <button className="profileHomeNavItem faFont textSize1 textClr3 flex alignCenter" onClick={()=>changeMenuHandler("editUserInfo")}>
  //                         <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="20" width="25" xmlns="http://www.w3.org/2000/svg"><g id="Edit"><g><path d="M3.548,20.938h16.9a.5.5,0,0,0,0-1H3.548a.5.5,0,0,0,0,1Z"></path><path d="M9.71,17.18a2.587,2.587,0,0,0,1.12-.65l9.54-9.54a1.75,1.75,0,0,0,0-2.47l-.94-.93a1.788,1.788,0,0,0-2.47,0L7.42,13.12a2.473,2.473,0,0,0-.64,1.12L6.04,17a.737.737,0,0,0,.19.72.767.767,0,0,0,.53.22Zm.41-1.36a1.468,1.468,0,0,1-.67.39l-.97.26-1-1,.26-.97a1.521,1.521,0,0,1,.39-.67l.38-.37,1.99,1.99Zm1.09-1.08L9.22,12.75l6.73-6.73,1.99,1.99Zm8.45-8.45L18.65,7.3,16.66,5.31l1.01-1.02a.748.748,0,0,1,1.06,0l.93.94A.754.754,0,0,1,19.66,6.29Z"></path></g></g></svg>
  //                         ویرایش پروفایل
  //                     </button>
  //                     <button className="profileHomeNavItem faFont textSize1 textClr3 flex alignCenter" onClick={()=>changeMenuHandler("myClasses")}>
  //                         <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" height="13" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z"></path></svg>
  //                         کلاس های من
  //                     </button>
  //                 </div>
  //             }
  //             {
  //                 activeMenu === "editUserInfo" && <ProfileEditUser />
  //             }
  //             {
  //                 activeMenu === "myClasses" && <ProfileClasses />
  //             }
  //         </div>
  //     </section>
  // }
  // </>
}
