import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { scrollToTop } from "./Methods";
import NavsList from "./NavsList";
import { AppContext } from "../App";
import PagePagintaion from "./PagePagination";
import ProfileSubLoading from "./ProfileSubLoading";
import EmptyResult from "./EmptyResult";
import ProductsGridItem from "./ProductsGridItem";
import "../Style/products.css";
export default function Products() {
  const params = useParams();
  const navigate = useNavigate();
  const { userData } = useContext(AppContext);
  const { siteInfo } = useContext(AppContext);
  const [userInfo] = userData;
  const [siteData] = siteInfo;
  const [firstLoading, setFirstLoading] = useState(true);
  const firstLoad = useRef(true);
  const pageNumber = useRef(1);
  const cat = useRef("");
  const minPrice = useRef(0);
  const maxPrice = useRef(null);
  const [showFilterList, setShowFilterList] = useState(false);
  const q = useRef(null);
  const [data, setData] = useState({
    loading: false,
    products: [],
    catname: "",
    subcatname: "",
  });
  const [searchParam] = useSearchParams();
  const [subProductToggleMenu, setSubProductToggleMenu] = useState({});
  useEffect(() => {
    if (firstLoad.current) {
      if (!params.cat) {
        navigate("/");
      }
      scrollToTop();
      document.title = "فروشگاه محصولات با ما می ر سی";
      firstLoad.current = false;
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (siteData) {
      let tempSubToggleMenu = {};
      siteData.productsCat.map((item) => {
        tempSubToggleMenu[item.id] = +params.cat === +item.id;
      });
      setSubProductToggleMenu(tempSubToggleMenu);
    }
  }, [siteData]);
  function fetchData() {
    pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
    const data = new FormData();
    data.append("pageCount", 10);
    data.append("pageIndex", pageNumber.current);
    data.append("subCategory", params.subcat ? params.subcat : -1);
    data.append("category", params.cat);
    data.append("token", userInfo.tkn);
    fetch("https://crm.bamamiresi.com/Enrollment/GetProducts", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        setFirstLoading(false);
        const serverData = json[0];
        if (serverData.code !== 200) {
          throw new Error("Error happend");
        }
        setData({
          loading: false,
          totalPage: +serverData.totalPage,
          products: serverData.products,
          catname: serverData.catname,
          subcatname: serverData.subcatname,
        });
      })
      .catch((err) => {
        navigate("/505");
      });
  }
  function changePage(newPageNumber) {
    scrollToTop();
    setData({
      ...data,
      loading: true,
    });
    searchParam.set("p", newPageNumber);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParam}`
    );
    fetchData();
  }
  return (
    <>
      {firstLoading ? (
        <>
          <section className="flex flexCol container blogsLoadingSection">
            <div className="flex flexCol loadingLatestBlogs rel">
              <div className="loaderItem"></div>
            </div>
            <div className="grid loadingBlogsMiddleGrid">
              <div className="flex flexCol loadingBlogSearchDiv rel">
                <div className="loaderItem"></div>
              </div>
              <div className="grid resultCourseLoadingGrid">
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
                <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                  <div className="loaderItem"></div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          {params.subcat ? (
            <NavsList
              data={[
                { name: data.catname, link: `/products/${params.cat}` },
                { name: data.subcatname, link: `/products/${params.subcat}` },
              ]}
            />
          ) : (
            <NavsList
              data={[{ name: data.catname, link: `/products/${params.cat}` }]}
            />
          )}
          <section className="grid blogsListGrid container" id="blogsListGrid">
            <div className="grid mainGridRightDiv productsRightDiv">
              <form
                action="/search"
                className="flex flexCol rightSideSearchDiv rel"
              >
                <input
                  name="q"
                  type="text"
                  className="faFont textSize1 textClr3 courseSearchInput"
                  autoComplete="off"
                  placeholder="جستجو در محصولات"
                />
                <input type="hidden" name="cat" />
                <button className="flex flexCenter courseSeachSubmitBtn">
                  <svg
                    className="searchIcon"
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <path d="m21 21-4.3-4.3"></path>
                  </svg>
                  <span className="hideText">search</span>
                </button>
              </form>
              <div className="flex flexCol filterSection">
                <button
                  className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween"
                  onClick={() => setShowFilterList(!showFilterList)}
                >
                  <span className="hideText">categories</span>
                  {showFilterList ? (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                    </svg>
                  ) : (
                    <svg
                      className="filterIcon"
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
                    </svg>
                  )}
                </button>
                <div
                  className={
                    showFilterList
                      ? "flex flexCol filterListDiv active customScrollbar "
                      : "filterListDiv customScrollbar"
                  }
                >
                  <div className="flex flexCol mobileExpandParentDiv2">
                    {siteData &&
                      siteData.productsCat &&
                      siteData.productsCat.map((item, index) => {
                        return (
                          <div
                            className="flex flexCol rel firstSubList"
                            key={index}
                          >
                            <Link
                              to={`/products/${item.id}`}
                              className={`faFont navlinkItem flex textSize9 textClr3 productNavItem ${
                                +params.cat === +item.id
                                  ? "productActiveNavItem"
                                  : ""
                              }`}
                            >
                              {item.name}
                            </Link>
                            <button
                              className={
                                subProductToggleMenu[item.id]
                                  ? "subToggleBtn flex flexCenter textClr3 toggle"
                                  : "subToggleBtn flex flexCenter textClr3"
                              }
                              onClick={() =>
                                setSubProductToggleMenu({
                                  ...subProductToggleMenu,
                                  [item.id]: !subProductToggleMenu[item.id],
                                })
                              }
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                              </svg>
                            </button>
                            <div className="flex flexCol rel secendSubList">
                              <div className="flex flexCol">
                                {item.subcategory.map((sub, index) => {
                                  return (
                                    <Link
                                      to={`/products/${item.id}/${sub.id}`}
                                      key={index}
                                      className={`faFont   flex textSize9 textClr3 ${
                                        +params.subcat === +sub.id
                                          ? "productActiveNavItem"
                                          : ""
                                      }`}
                                    >
                                      {sub.name}
                                    </Link>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flexCol rel">
              {data.products && (
                <>
                  {data.products.length > 0 ? (
                    <>
                      <div className="grid productGridList rel">
                        {data.products.map((item, index) => {
                          return <ProductsGridItem data={item} key={index} />;
                        })}
                        {data.loading && <ProfileSubLoading />}
                      </div>
                      <PagePagintaion
                        data={{
                          totalNumber: data.totalPage,
                          changePage: changePage,
                          currentPage: pageNumber,
                        }}
                      />
                    </>
                  ) : (
                    <EmptyResult
                      linkAddres={"/"}
                      linkText={"رفتن به صفحه ی اصلی"}
                      text={"محصولی یافت نشد."}
                    />
                  )}
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
}
