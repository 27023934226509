import React from "react";
import "../Style/payResult.css";
import { Link } from "react-router-dom";
export default function PayResult() {
  return (
    <section className="flex flexCol flexCenter payResult">
      <object
        data="/icon/done.svg"
        type="image/svg+xml"
        width="60"
        height="60"
        className="payResultIcon"
      >
        Your browser does not support SVGs.
      </object>
      <p className="faFont payDoneText">پرداخت با موفقیت انجام شد.</p>
      <Link to={"/profile/orders"} className="faFont payDoneText2">
        مشاهده همه سفارش های من
      </Link>
    </section>
  );
}
