import React from "react";
import { Link } from "react-router-dom";
import CustomImage from "./CustomImage";
export default function ProductsGridItem({ data }) {
  return (
    <div className="productListItem grid rel">
      <Link
        to={`/product/${data.id}`}
        className="flex flexCol productImgLinkItem"
        draggable="false"
      >
        <CustomImage
          data={{
            src: data.image,
            alt: data.name,
            class: "productImg",
            height: 150,
            width: 250,
          }}
        />
      </Link>
      <div className="flex flexCol courseInfoDiv productInfoItem">
        <Link
          to={`/product/${data.id}`}
          className="faFont textClr3 courseName"
          draggable="false"
        >
          {data.name}
        </Link>
        <div className="productsFooterItem flex alignCenter justifyBetween">
          <div className="flex flexCol">
            {+data.discount !== 0 && data.discount !== "" && (
              <p className="faFont productDiscount flex alignCenter">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2426 6.34319C16.6331 5.95266 17.2663 5.95266 17.6568 6.34319C18.0474 6.73371 18.0474 7.36688 17.6568 7.7574L7.75734 17.6569C7.36681 18.0474 6.73365 18.0474 6.34313 17.6569C5.9526 17.2664 5.9526 16.6332 6.34313 16.2427L16.2426 6.34319Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.87866 9.87872C9.09761 10.6598 7.83128 10.6598 7.05023 9.87872C6.26918 9.09767 6.26918 7.83134 7.05023 7.05029C7.83128 6.26924 9.09761 6.26924 9.87866 7.05029C10.6597 7.83134 10.6597 9.09767 9.87866 9.87872Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M14.1213 16.9498C14.9023 17.7308 16.1687 17.7308 16.9497 16.9498C17.7308 16.1687 17.7308 14.9024 16.9497 14.1214C16.1687 13.3403 14.9023 13.3403 14.1213 14.1214C13.3403 14.9024 13.3403 16.1687 14.1213 16.9498Z"
                    fill="currentColor"
                  ></path>
                </svg>
                {data.discount}
              </p>
            )}
          </div>
          {data["has_stock"] ? (
            <>
              <div className="flex alignCenter gap5">
                {+data.price !== 0 &&
                  +data.discount !== 0 &&
                  data.discount !== "" && (
                    <p className="faFont priceBeforeOff">
                      {(+data.price).toLocaleString()}
                    </p>
                  )}
                <p className="faFont courseItemPrice">
                  {+data.price !== 0
                    ? +data.discount !== 0 && data.discount !== ""
                      ? (
                          +data.price -
                          (+data.price * +data.discount) / 100
                        ).toLocaleString() + " تومان"
                      : (+data.price).toLocaleString() + " تومان"
                    : "رایگان"}
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="flex alignCenter gap5">
                <p className="faFont productNotExistText">محصول تمام شده است</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
