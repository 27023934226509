import React, { useContext, useEffect, useRef, useState } from "react";
import "../Style/productDetails.css";
import NavsList from "./NavsList";
import { cutText, MyComponent, scrollToTop } from "./Methods";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppContext } from "../App";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import AddToCart from "./AddToCart";
import CustomImage from "./CustomImage";
import Comment from "./Comment";
import ProductsGridItem from "./ProductsGridItem";

export default function ProductDetailsPage() {
  const navigate = useNavigate();
  const [firstLoading, setFirstLoading] = useState(true);
  const firstLoad = useRef(true);
  const params = useParams();
  const { userData } = useContext(AppContext);
  const [userInfo] = userData;
  const [data, setData] = useState();
  useEffect(() => {
    if (!params.id) {
      navigate("/");
      return;
    }
    if (firstLoad.current) {
      firstLoad.current = false;
      scrollToTop();
      fetchData();
      document.title = `قیمت ،توضیحات و مشخصات محصول`;
    }
  }, []);
  function fetchData() {
    const data = new FormData();
    data.append("ID", params.id);
    data.append("token", userInfo.tkn);
    fetch("https://crm.bamamiresi.com/Enrollment/iproduct", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        setFirstLoading(false);
        const serverData = json[0];
        scrollToTop();
        document.title = `قیمت ،توضیحات و مشخصات ${serverData.name}`;
        setData(serverData);
      })
      .catch((err) => {
        navigate("/505");
      });
  }
  const generateProductImgSlider = () => {
    return (
      <div className="image-slider">
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={10}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            renderBullet: (index, className) => {
              return `<img src="${data.imgs[index].src}" class="productImgBullet ${className}" width="40" height="40" draggable="false"/>`;
            },
          }}
        >
          {data.imgs.map((image, index) => (
            <SwiperSlide key={index}>
              <CustomImage
                data={{
                  src: image.src,
                  alt: `${data.name} Slide ${index + 1}`,
                  class: "productImgSlider",
                  height: 100,
                  width: 100,
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };
  return (
    <>
      {firstLoading ? (
        <section className="flex flexCol container blogDetailsLoadingSection">
          <div className="flex flexCol blogNavLoading rel productNavLoading">
            <div className="loaderItem"></div>
          </div>
          <div className="grid productLoadingGrid">
            <div className="flex flexCol productLoadingItem rel">
              <div className="loaderItem"></div>
            </div>
            <div className="productLeftLoading grid">
              <div className="flex flexCol productLoadingItem rel">
                <div className="loaderItem"></div>
              </div>
              <div className="flex flexCol productLoadingItem rel">
                <div className="loaderItem"></div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <NavsList
            data={[
              { name: cutText(data.name, 50), link: `/product/${params.id}` },
            ]}
          />
          <main className="productMainDiv flex flexCol container">
            <div className="flex flexCol productImgSlider">
              {generateProductImgSlider()}
            </div>
            <div className="grid productMainSubGrid">
              <div className="flex flexCol productMiddleDiv">
                <h1 className="faFont productName">{data.name}</h1>
                <h2 className="faFont productPropTitle flex alignCenter">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M12 19.875c0 .621 -.512 1.125 -1.143 1.125h-5.714a1.134 1.134 0 0 1 -1.143 -1.125v-15.875a1 1 0 0 1 1 -1h5.857c.631 0 1.143 .504 1.143 1.125z"></path>
                    <path d="M12 9h-2"></path>
                    <path d="M12 6h-3"></path>
                    <path d="M12 12h-3"></path>
                    <path d="M12 18h-3"></path>
                    <path d="M12 15h-2"></path>
                    <path d="M21 3h-4"></path>
                    <path d="M19 3v18"></path>
                    <path d="M21 21h-4"></path>
                  </svg>
                  ویژگی ها
                </h2>
                <div className="flex flexCol topPropList">
                  {data.propsList &&
                    data.propsList.map((item, index) => {
                      if (index < 3) {
                        return (
                          <div className="grid topPropsItem" key={index}>
                            <p className="faFont topPropTitleName">
                              {item.name}
                            </p>
                            <span>:</span>
                            <p className="faFont topPropItemDesc">
                              {item.desc}
                            </p>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="flex flexCol productLeftDiv">
                <div className="productCartDiv grid">
                  <div className="flex flexCol productCartTopDiv">
                    {data.number > 0 ? (
                      <AddToCart data={{ id: params.id, type: 30004 }} />
                    ) : (
                      <p className="faFont flex flexCenter productNotExistBtn">
                        محصول تمام شده است
                      </p>
                    )}
                  </div>
                  <div className="productCartPriceDiv flex flexCol rel">
                    {data["has_stock"] ? (
                      <>
                        <div className="flex flexCol gap5 productCartLeftDiv">
                          {+data.price !== 0 &&
                            +data.discount !== 0 &&
                            data.discount !== "" && (
                              <>
                                <div className="flex alignCenter gap5 justifyBetween productTitleDiv discountDiv">
                                  <p className="faFont productPriceTitleText">
                                    قیمت :
                                  </p>
                                  <p className="faFont priceBeforeOff flex alignCenter gap5">
                                    {(+data.price).toLocaleString()}
                                    <span className="tomanText">تومان</span>
                                  </p>
                                </div>
                                <div className="flex alignCenter gap5 justifyBetween productTitleDiv percentDiv">
                                  <p className="faFont productPriceTitleText">
                                    تخفیف :
                                  </p>
                                  <p className="faFont productPercent flex alignCenter">
                                    {data.discount}
                                    <svg
                                      stroke="currentColor"
                                      fill="currentColor"
                                      strokeWidth="0"
                                      viewBox="0 0 16 16"
                                      height="1em"
                                      width="1em"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M3.60962 12.6877L11.6096 2.68765L12.3905 3.31235L4.39049 13.3123L3.60962 12.6877Z"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M4.5 6.5C3.67157 6.5 3 5.82843 3 5C3 4.17157 3.67157 3.5 4.5 3.5C5.32843 3.5 6 4.17157 6 5C6 5.82843 5.32843 6.5 4.5 6.5ZM4.5 7.5C5.88071 7.5 7 6.38071 7 5C7 3.61929 5.88071 2.5 4.5 2.5C3.11929 2.5 2 3.61929 2 5C2 6.38071 3.11929 7.5 4.5 7.5Z"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.5 12.5C10.6716 12.5 10 11.8284 10 11C10 10.1716 10.6716 9.5 11.5 9.5C12.3284 9.5 13 10.1716 13 11C13 11.8284 12.3284 12.5 11.5 12.5ZM11.5 13.5C12.8807 13.5 14 12.3807 14 11C14 9.61929 12.8807 8.5 11.5 8.5C10.1193 8.5 9 9.61929 9 11C9 12.3807 10.1193 13.5 11.5 13.5Z"
                                      ></path>
                                    </svg>
                                  </p>
                                </div>
                              </>
                            )}
                          <div className="flex alignCenter gap5 justifyBetween productTitleDiv">
                            <p className="faFont productPriceTitleText">
                              قابل پرداخت :
                            </p>

                            {+data.price !== 0 ? (
                              <p className="faFont productPrice flex alignCenter">
                                {+data.discount !== 0 && data.discount !== ""
                                  ? (
                                      +data.price -
                                      (+data.price * +data.discount) / 100
                                    ).toLocaleString()
                                  : (+data.price).toLocaleString()}

                                <span>تومان</span>
                              </p>
                            ) : (
                              <p className="faFont courseItemPrice">رایگان</p>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex alignCenter gap5">
                          <p className="faFont productNotExistText">
                            محصول تمام شده است
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="grid productSupportDiv">
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/support.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      پشتیبانی 24 ساعته
                    </span>
                  </div>
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/delivery.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon2"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      ارسال به سراسر کشور
                    </span>
                  </div>
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/guarantee.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      پرداخت از طریق درگاه
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </main>
          {data.decription && (
            <section className="flex flexCol container descriptionSection">
              <h3 className="faFont descriptionTitle flex alginCenter">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z"></path>
                  <path d="M14 8H8"></path>
                  <path d="M16 12H8"></path>
                  <path d="M13 16H8"></path>
                </svg>
                توضیحات
              </h3>
              <div className="flex flexCol blogContentList faFont content rel">
                {MyComponent({ htmlContent: data.decription })}
              </div>
            </section>
          )}
          {data.propsList && data.propsList.length > 3 && (
            <section className="propsSection flex flexCol container">
              <h3 className="faFont propsSectionTitle flex alginCenter gap10">
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  height="20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 19.875c0 .621 -.512 1.125 -1.143 1.125h-5.714a1.134 1.134 0 0 1 -1.143 -1.125v-15.875a1 1 0 0 1 1 -1h5.857c.631 0 1.143 .504 1.143 1.125z"></path>
                  <path d="M12 9h-2"></path>
                  <path d="M12 6h-3"></path>
                  <path d="M12 12h-3"></path>
                  <path d="M12 18h-3"></path>
                  <path d="M12 15h-2"></path>
                  <path d="M21 3h-4"></path>
                  <path d="M19 3v18"></path>
                  <path d="M21 21h-4"></path>
                </svg>
                مشخصات محصول
              </h3>
              <div className="flex flexCol propsSectionList">
                {data.propsList.map((item, index) => {
                  return (
                    <div className="propsSectionItem grid" key={index}>
                      <p className="faFont propsSectionText">{item.name}</p>
                      <p className="faFont propsSectionText">:</p>
                      <p className="faFont propsSectionText">{item.desc}</p>
                    </div>
                  );
                })}
              </div>
            </section>
          )}
          <div className="commentBorder container"></div>
          <Comment
            data={{
              totalRate: 4.25,
              totalRateNumber: 25,
              comments: data.commentsList ? data.commentsList : [],
              totalComments: 4,
              id: params.id,
            }}
          />
          {data.relativeProducts && data.relativeProducts.length > 0 && (
            <section className="relativeProductSection flex flexCol container">
              <h3 className="relativeProductTitle faFont flex alignCenter gap5">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="18"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z"></path>
                </svg>
                محصولات مرتبط
              </h3>
              <Swiper
                className="swiper productSwiper rel"
                spaceBetween={10}
                slidesPerView={1}
                breakpoints={{
                  550: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  967: {
                    slidesPerView: 4,
                  },
                  1350: {
                    slidesPerView: 5,
                  },
                }}
              >
                {data.relativeProducts.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <ProductsGridItem data={item} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </section>
          )}
        </>
      )}
    </>
  );
}
