import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
export default function ProfileOrderDetails(){
    const params = useParams();
    const navigate = useNavigate();
    const [data,setData] = useState();
    const [firstLoading ,setFirstLoading] = useState(true);
    const firstLoad = useRef(true); 
    useEffect(() => {
        document.title = "جزئیات سفارش";
        if(firstLoad.current){
            firstLoad.current = false;
            fetchProfileOrderDetails();
        }
    }, []);
    
    function fetchProfileOrderDetails(){
        const data = new FormData();
        // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
        data.append("orderId", params.id);
        data.append("token","");
        fetch("https://crm.bamamiresi.com/Enrollment/getProfileOrderDetails",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            setFirstLoading(false);
            const serverData = json[0];
            if (serverData.code !== 200) {
                throw new Error("Error happend");
            }
            setData(serverData);
        })
        .catch((err) => {
            console.log(err);
            // navigate("/505");
            setFirstLoading(false);
            const serverRes = {
                id : 21168819,
                name : "کتاب آموزش پیانو و ۲ مورد دیگر",
                state : "inProgress",
                stateText : "در حال پیگیری",
                price : "125000",
                trackingCode : "26500841",
                weight : "1250 گرم",
                deliveryPrice : "12565852",
                postCode : "22187481444451211",
                discountPrice : 120000,
                itemsList : [
                    {
                        name : "کتاب اموزش پیانو",
                        count : 1,
                        price : "125000",
                        discount : 0,
                        id : 2226222,
                        img : "/image/blogCover.jpg",
                        url : "/store/2226222"
                    },
                    {
                        name : "پیک گیتار",
                        count : 1,
                        price : "45000",
                        discount : 5,
                        id : 8186,
                        img : "/image/blogCover.jpg",
                        url : "/store/186888"
                    },
                    {
                        name : "کاور گیتار",
                        count : 2,
                        price : "350000",
                        discount : 10,
                        id : 18181616,
                        img : "/image/blogCover.jpg",
                        url : "/store/18181616"
                    },
                ],
            };
            setData(serverRes);
        });
    }
    function getSvg(state){
        switch (state) {
            case "inProgress":
                return <svg className="stateIcon" stroke="currentColor" fill="#6cbdff" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>    
            case "complete":
                return <svg className="stateIcon" stroke="currentColor" fill="#2ab82a" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path></svg>
            case "canceled":
                return <svg className="stateIcon" stroke="currentColor" fill="#ff6347" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm86.63 272L320 342.63l-64-64-64 64L169.37 320l64-64-64-64L192 169.37l64 64 64-64L342.63 192l-64 64z"></path></svg>
            default:
                break;
        }
    }
    return <>
        {
            firstLoading ? <>
            <div className="flex flexCenter profileLoading2 rel">
                <div className="profileSubLoadingSpin"></div>
                <div className="flex flexCol userDesktopLoading">
                    <div className="flex flexCol rel userDesktopLeftDiv">
                        <div className="loaderItem"></div>
                    </div>
                </div>        
            </div>
            </> :
            <>
            <section className="flex flexCol profileOrderDetailsMainDiv">
                <div className="flex flexCol profileBaseInfoDiv rel">
                    <h2 className="faFont orderName">
                        {
                            data.name
                        }
                    </h2>
                    <p className={`faFont profileOrderState flex alignCenter gap5 ${data.state}`}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="16" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"></path></svg>
                        <span className="profileOrderSpanTitle">
                            وضعیت : 
                        </span>
                        <span>
                            {data.stateText}
                        </span>
                    </p>
                    <p className="faFont profileOrderTrackingCode flex alignCenter gap5">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="18" width="18" xmlns="http://www.w3.org/2000/svg"><rect width="5" height="5" x="3" y="3" rx="1"></rect><rect width="5" height="5" x="16" y="3" rx="1"></rect><rect width="5" height="5" x="3" y="16" rx="1"></rect><path d="M21 16h-3a2 2 0 0 0-2 2v3"></path><path d="M21 21v.01"></path><path d="M12 7v3a2 2 0 0 1-2 2H7"></path><path d="M3 12h.01"></path><path d="M12 3h.01"></path><path d="M12 16v.01"></path><path d="M16 12h1"></path><path d="M21 12v.01"></path><path d="M12 21v-1"></path></svg>
                        <span className="profileOrderSpanTitle">
                            کد سفارش :
                        </span>
                        <span>
                            {data.trackingCode}
                        </span>
                    </p>
                    <p className="faFont profileOrderText flex alignCenter gap5">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="18" width="17" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-.5 1.5 1.96 2.5H17V9.5h2.5zM6 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2.22-3c-.55-.61-1.33-1-2.22-1s-1.67.39-2.22 1H3V6h12v9H8.22zM18 18c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg>
                        <span className="profileOrderSpanTitle">
                            کد رهگیری پستی :
                        </span>
                        <span>
                            {data.postCode}
                        </span>
                    </p>
                    <p className="faFont profileOrderText flex alignCenter gap5">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="5" r="3"></circle><path d="M6.5 8a2 2 0 0 0-1.905 1.46L2.1 18.5A2 2 0 0 0 4 21h16a2 2 0 0 0 1.925-2.54L19.4 9.5A2 2 0 0 0 17.48 8Z"></path></svg>
                        <span className="profileOrderSpanTitle">
                            وزن سفارش :
                        </span>
                        <span>
                            {data.weight}
                        </span>
                    </p>
                    <div className="borderDiv"></div>
                    <p className="faFont profileOrderText flex alignCenter gap5">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 32 32" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M 0 6 L 0 8 L 19 8 L 19 23 L 12.84375 23 C 12.398438 21.28125 10.851563 20 9 20 C 7.148438 20 5.601563 21.28125 5.15625 23 L 4 23 L 4 18 L 2 18 L 2 25 L 5.15625 25 C 5.601563 26.71875 7.148438 28 9 28 C 10.851563 28 12.398438 26.71875 12.84375 25 L 21.15625 25 C 21.601563 26.71875 23.148438 28 25 28 C 26.851563 28 28.398438 26.71875 28.84375 25 L 32 25 L 32 16.84375 L 31.9375 16.6875 L 29.9375 10.6875 L 29.71875 10 L 21 10 L 21 6 Z M 1 10 L 1 12 L 10 12 L 10 10 Z M 21 12 L 28.28125 12 L 30 17.125 L 30 23 L 28.84375 23 C 28.398438 21.28125 26.851563 20 25 20 C 23.148438 20 21.601563 21.28125 21.15625 23 L 21 23 Z M 2 14 L 2 16 L 8 16 L 8 14 Z M 9 22 C 10.117188 22 11 22.882813 11 24 C 11 25.117188 10.117188 26 9 26 C 7.882813 26 7 25.117188 7 24 C 7 22.882813 7.882813 22 9 22 Z M 25 22 C 26.117188 22 27 22.882813 27 24 C 27 25.117188 26.117188 26 25 26 C 23.882813 26 23 25.117188 23 24 C 23 22.882813 23.882813 22 25 22 Z"></path></svg>
                        <span className="profileOrderSpanTitle">
                           هزینه ارسال :
                        </span>
                        <span>
                            {(+data.deliveryPrice).toLocaleString()} تومان
                        </span>
                    </p>
                    <p className="faFont profileOrderPrice flex alignCenter gap5">
                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="17" width="17" xmlns="http://www.w3.org/2000/svg"><line x1="19" x2="5" y1="5" y2="19"></line><circle cx="6.5" cy="6.5" r="2.5"></circle><circle cx="17.5" cy="17.5" r="2.5"></circle></svg>
                        <span className="profileOrderSpanTitle">
                            تخفیف : 
                        </span>
                        <span>
                            {(+data.discountPrice).toLocaleString()} تومان
                        </span>
                    </p>
                    <p className="faFont profileOrderPrice flex alignCenter gap5">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="18" width="17" xmlns="http://www.w3.org/2000/svg"><path d="M244.24,60a8,8,0,0,0-7.75-.4c-42.93,21-73.59,11.16-106,.78-34-10.89-69.25-22.14-117.95,1.64A8,8,0,0,0,8,69.24V189.17a8,8,0,0,0,11.51,7.19c42.93-21,73.59-11.16,106.05-.78,19.24,6.15,38.84,12.42,61,12.42,17.09,0,35.73-3.72,56.91-14.06a8,8,0,0,0,4.49-7.18V66.83A8,8,0,0,0,244.24,60ZM232,181.67c-40.6,18.17-70.25,8.69-101.56-1.32-19.24-6.15-38.84-12.42-61-12.42a122,122,0,0,0-45.4,9V74.33c40.6-18.17,70.25-8.69,101.56,1.32S189.14,96,232,79.09ZM128,96a32,32,0,1,0,32,32A32,32,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM56,96v48a8,8,0,0,1-16,0V96a8,8,0,1,1,16,0Zm144,64V112a8,8,0,1,1,16,0v48a8,8,0,1,1-16,0Z"></path></svg>
                        <span className="profileOrderSpanTitle">
                            مبلغ پرداختی : 
                        </span>
                        <span>
                            {(+data.price).toLocaleString()} تومان
                        </span>
                    </p>
                    {
                        getSvg(data.state)
                    }
                </div>
                <div className="flex flexCol profileOrderItemsDiv">
                    <h3 className="faFont profileOrderListTitle">
                        جزئیات سفارش
                    </h3>
                    <div className="flex flexCol profileOrderItemList">
                        <div className="grid profileOrderItemTitleDiv">
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                ردیف
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5 profileOrderNameDetails">
                                نام
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                تعداد
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                قیمت
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                تخفیف
                            </p>
                            <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                جمع
                            </p>
                        </div>
                        {
                            data && data.itemsList && data.itemsList.map((item,index)=>{
                                return <div className="grid profileOrderDetailsItem" key={index}>
                                    <p className="faFont profileOrderDetailsCounter">
                                        {
                                            index + 1
                                        }
                                    </p>
                                    <img src="/image/blogCover.jpg" alt="item name" className="profileOrderDetailsItemImg" loading="lazy" draggable="false" height={90} width={90}/>
                                    <div className="grid profileOrderDetailsInfoDiv">
                                        <Link to={item.url} className="faFont profileOrderDetailsText profileOrderNameDetails flex alginCenter gap5">
                                            <span className="orderTitleSpan">
                                                نام :
                                            </span>
                                            <span>
                                                {
                                                    item.name
                                                }
                                            </span>
                                        </Link>
                                        <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                            <span className="orderTitleSpan">
                                                تعداد :
                                            </span>
                                            <span>
                                                {
                                                    item.count
                                                }
                                            </span>
                                        </p>
                                        <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                            <span className="orderTitleSpan">
                                                قیمت  :
                                            </span>
                                            <span>
                                                {
                                                    (+item.price).toLocaleString()
                                                }
                                            </span>
                                        </p>
                                        <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                            <span className="orderTitleSpan">
                                                تخفیف  :
                                            </span>
                                            <span>
                                                {item.discount}%
                                            </span>
                                        </p>
                                        <p className="faFont profileOrderDetailsText flex alginCenter gap5">
                                            <span className="orderTitleSpan">
                                                جمع :
                                            </span>
                                            <span>
                                                {
                                                    (((+item.price - (+item.price * +item.discount) / 100)) * +item.count).toLocaleString()
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </section>
            </>
        }
    </>
}