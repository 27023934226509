import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import NavsList from "./NavsList";
import { scrollToTop } from "./Methods";
import FilterItem from "./FilterItem";
import PagePagintaion from "./PagePagination";
import FullBanner from "./FullBanner";
import "../Style/teacherPage.css";
export default function TeachersPage(){
    const [data,setData] = useState({
        resultList : null,
        catsList : null,
        middleBanner : null,
        totalPage : 0,
        loading:false
    });
    const [firstLoading,setFirstLoading] = useState(true);
    const pageNumber = useRef(1);
    const teacherName = useRef("");
    const [showFilterList,setShowFilterList] = useState(false);
    const [searchParam,] = useSearchParams();
    const firstLoad = useRef(true);
    const [activeTags,setActiveTags] = useState({
        cat : searchParam.get("cat"),
    });
    const isSearchParamBaseQuery = useRef(true);
    useEffect(() => {
        scrollToTop();
        document.title = "تمام اساتید با ما می ر سی";
        if(firstLoad.current){
            firstLoad.current = false;
            fetchData();
        }
    }, []);
    // function fetchData(){
    //     pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
    //     scrollToTop();
    //     let catsList = [
    //         {fa:"آموزش گیتار",id:"guitar"},
    //         {fa:"آموزش پیانو",id:"piano"},
    //         {fa:"آموزش تئاتر",id:"teater"},
    //         {fa:"آموزش ویلون",id:"violon"},
    //     ];
    //     setTimeout(() => {
    //         setData({
    //             middleBanner : {
    //                 url : "",
    //                 cover: "/image/middleBanner.webp",
    //                 mobileCover: "/image/mobileMiddleBanner.webp",
    //                 title : "something" 
    //             },
    //             catsList : [
    //                 {fa:"آموزش گیتار",id:"guitar"},
    //             ],
    //             resultList : [
    //                 {
    //                     name : "سعید راد",
    //                     url : "teachers/teacherId",
    //                     cover : "/image/teacherImg.webp",
    //                     expert : "متخصص داده",
    //                     desc : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.",
    //                     id : 0
    //                 },
    //             ],
    //             totalPage : 10,
    //         });
    //         catsList.map((item)=>{
    //             if(item.id === params.query){
    //                 setActiveCat(item);
    //             }
    //         })
    //         setIsLoading({
    //             filter : false,
    //             data : false
    //         });
    //     }, 5000);
    // }
    function fetchData(){
        pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
        teacherName.current = searchParam.get("teacher") ? searchParam.get("teacher") : "";
        const data = new FormData();
        data.append("teacher", teacherName.current);
        data.append("pageCount", 10);
        data.append("pageIndex", pageNumber.current);
        fetch("https://crm.bamamiresi.com/Enrollment/GetTeachers",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            console.log(json);
            // if (json.error) {
            //     throw new Error(json.msg);
            // }
            // console.log(json[0]);
        })
        .catch((err) => {
            // navigate("/505");
            console.log(err);
            setFirstLoading(false);
            let serverData = {
                category : [ {name:"آموزش گیتار",latinName:"guitar"}],
            }
            let tempCatList = [
                {name:"آموزش گیتار",latinName:"guitar"},
            ];
            setData({
                middleBanner : {
                    url : "",
                    cover: "/image/middleBanner.webp",
                    mobileCover: "/image/mobileMiddleBanner.webp",
                    title : "something" 
                },
                suggestCat : tempCatList ,
                resultList : [
                    {
                        name : "سعید راد",
                        url : "teachers/teacherId",
                        cover : "/image/teacherImg.webp",
                        expert : "متخصص داده",
                        desc : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد.",
                        id : 0
                    },
                ],
                totalPage : 3,
                loading : false
            });
            if(isSearchParamBaseQuery.current){
                isSearchParamBaseQuery.current = false;
                if(searchParam.size > 0){
                    let tempActiveCat = serverData.category.find(catItem=> catItem.latinName === activeTags.cat);
                    if(tempActiveCat){
                        tempActiveCat = tempActiveCat.name;
                    }else{
                        searchParam.delete("cat");
                        tempActiveCat = "";
                        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
                    }
                    setActiveTags({
                        cat :  tempActiveCat ,
                    });
                }
            }
        });
    }
    function changePage(newPageNumber){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        searchParam.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeTag(tagItem){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        setActiveTags({...activeTags,tag:tagItem.name});
        searchParam.set("tag",tagItem.latinName);
        searchParam.delete("p");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function changeCat(catItem){
        scrollToTop();
        setData({
            ...data,loading : true
        });
        setActiveTags({...activeTags,cat:catItem.name});
        searchParam.set("cat",catItem.latinName);
        searchParam.delete("p");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
        fetchData();
    }
    function removeTag(){
        setActiveTags({...activeTags,"tag":""});
        searchParam.delete("tag");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
    }
    function removeCat(){
        setActiveTags({...activeTags,"cat":""});
        searchParam.delete("cat");
        window.history.pushState({},"",`${window.location.pathname}?${searchParam}`);
    }
    return <>
        {
            firstLoading ? <section className="flex flexCol container blogsLoadingSection">
                <div className="flex flexCol loadingLatestBlogs rel">
                    <div className="loaderItem"></div>
                </div>
                <div className="grid loadingBlogsMiddleGrid">
                    <div className="flex flexCol loadingBlogSearchDiv rel">
                        <div className="loaderItem"></div>
                    </div>
                    <div className="grid resultCourseLoadingGrid">
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                        <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                            <div className="loaderItem"></div>
                        </div>
                    </div>
                </div>
            </section> : <>
                <NavsList data={[{name:"اساتید",link:"/teacher"}]} />
                {
                    activeTags.cat &&
                    <div className="flex alignCenter activeTagList container">
                        {
                            activeTags.cat && <button className="showActiveTagBtn faFont flex alignCenter gap5 faFont textSize9 textClr3 suggestTagItem" onClick={()=>removeCat()}>
                                {
                                    activeTags.cat
                                }
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path></svg>
                            </button>
                        }
                    </div>
                }
                <main className="grid mainGrid container" onClick={()=>{showFilterList && setShowFilterList(false)}}>
                    {
                        firstLoading.filter ? <div className="grid courseFilterLoading">
                            <div className="searchInputLoading loadingStoryItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol filterLoadingBtn loadingStoryItem">
                                <div className="loaderItem"></div>
                            </div>
                        </div> :
                        <div className="grid mainGridRightDiv">
                            <form action="/search" className="flex flexCol rightSideSearchDiv rel">
                                <input name="q" type="text" className="faFont textSize1 textClr3 courseSearchInput" autoComplete="off" placeholder="جستجو در مقالات"/>
                                <button className="flex flexCenter courseSeachSubmitBtn">
                                    <svg className="searchIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="11" cy="11" r="8"></circle><path d="m21 21-4.3-4.3"></path></svg>
                                    <span className="hideText">search</span>
                                </button>
                            </form>
                            <div className="flex flexCol filterSection">
                            <button className="activeCatBtn faFont textSize11 textClr3 flex alignCenter justifyBetween" onClick={()=>setShowFilterList(!showFilterList)}>
                                    <span className="hideText">
                                        categories
                                    </span>
                                    <svg className={showFilterList ? "activeIcon arrowIcon" : "arrowIcon"} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                    <svg className="filterIcon" stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
                                </button>
                                <div className={showFilterList ? "flex flexCol filterListDiv active customScrollbar" : "filterListDiv customScrollbar"}>
                                    {
                                        data.suggestCat && data.suggestCat.length > 0 && 
                                        <div className="filterRow flex flexCol" >
                                            <h4 className="faFont suggestCatTitle textSize1 textClr5">
                                                دسته بندی ها:
                                            </h4>
                                            <div className="flex flexCol suggestList">
                                                {
                                                    data.suggestCat.map((item,index)=>{
                                                        return <button className={`faFont textSize9 textClr3 flex alignCenter justifyBetween suggestCatItem ${activeTags.cat === item.name ? "activeSuggestTagItem" : ""}`} key={index} onClick={()=>changeCat(item)}>
                                                            {
                                                                item.name
                                                            }
                                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path></svg>
                                                        </button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        data.resultList ? <div className="flex flexCol rel courseSectionLeftDiv">
                            <div className="teachersList grid">
                                {
                                    data.resultList.map((item,index)=>{
                                        return <div className="flex flexCol teacherPageItem rel" key={index}>
                                            <Link className="flex flexCol">
                                                <img src={item.cover} alt={item.name} className="teacherPageImg" />
                                            </Link>
                                            <Link to={"/teacher/" + item.id} className="faFont textSize11 textClr3 teacherPageName flex alignCenter">
                                                <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="8" r="5"></circle><path d="M20 21a8 8 0 1 0-16 0"></path></svg>
                                                {
                                                    item.name
                                                }
                                            </Link>
                                            <p className="faFont textSize8 textClr5 teacherPageDesc">
                                                {
                                                    item.expert
                                                }
                                            </p>
                                        </div>
                                    })
                                }
                            </div>
                            <PagePagintaion data={{totalNumber:data.totalPage,
                                changePage : changePage, currentPage:pageNumber}} />
                            {
                                data.loading && <div className="flex flexCol loadingMoreData container">
                                    <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
                                        <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="12" y1="2" x2="12" y2="6"></line><line x1="12" y1="18" x2="12" y2="22"></line><line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line><line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line><line x1="2" y1="12" x2="6" y2="12"></line><line x1="18" y1="12" x2="22" y2="12"></line><line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line><line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line></svg>
                                    </div>
                                </div>
                            }
                        </div>
                        : <div className="grid resultCourseLoadingGrid">
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                            <div className="flex flexCol rel loadingStoryItem resultCourseLoadingItem">
                                <div className="loaderItem"></div>
                            </div>
                        </div>
                    }
                </main>
                {data.middleBanner && <FullBanner data={data.middleBanner} />}
            </>
        }
    </>
}