import React, { useEffect, useRef, useState } from "react";
import "../Style/imageFullSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperNavBtns from "./SwiperNavBtn";

export default function ImageFullSlider({
  data,
  showFullImageSlider,
  setShowFullImageSlider,
}) {
  let hasNav = false;
  const sliderRef = useRef();
  const [myIndex, setMyIndex] = useState(0);
  if (data.list && data.list.length > 1) {
    hasNav = {
      prevEl: ".goToPreLargeSlide",
      nextEl: ".goToNextLargeSlide",
    };
  }
  useEffect(() => {
    setMyIndex(showFullImageSlider - 1);
  }, [showFullImageSlider]);
  useEffect(() => {
    sliderRef.current.swiper.slideTo(myIndex);
  }, [myIndex]);
  return (
    <>
      <button
        className={`fullVideoCloser ${showFullImageSlider && "show"}`}
        onClick={() => setShowFullImageSlider(0)}
      >
        <svg
          className="fullVideoIcon"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 512 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path>
        </svg>
        <span className="hideText">close</span>
      </button>
      <div
        className={`flex flexCol flexCenter imgSliderContainer ${
          showFullImageSlider ? "show" : ""
        }`}
      >
        <Swiper
          className="swiper imgSlider rel"
          spaceBetween={10}
          slidesPerView={1}
          ref={sliderRef}
        >
          {data.list.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="flex flexCol flexCenter rel fullImgSliderItem">
                  <img
                    src={item.img}
                    alt={item.name}
                    className="fullImgSliderImg"
                    draggable={false}
                    width="100%"
                  />
                  {item.name && (
                    <p className="faFont textSize12 textClr3 imageFullSlderText">
                      {item.name}
                    </p>
                  )}
                </div>
              </SwiperSlide>
            );
          })}
          {hasNav && <SwiperNavBtns />}
        </Swiper>
      </div>
    </>
  );
}
