import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../Style/profileAddAddress.css";
import { scrollToTop, showModal } from "./Methods";
import { AppContext } from "../App";

export default function ProfileAddAddress() {
  const navigate = useNavigate();
  const firstLoad = useRef(true);
  const [params] = useSearchParams();
  const map = useRef(undefined);
  const [formData, setFormData] = useState({
    pointX: 35.7,
    pointY: 51.33,
    address: "",
    postalCode: "",
  });
  const currentData = {
    x: params.get("x") ? params.get("x") : 35.7,
    y: params.get("y") ? params.get("y") : 51.33,
  };
  const {
    userData: [userInfo],
  } = useContext(AppContext);
  useEffect(() => {
    if (firstLoad.current) {
      scrollToTop();
      firstLoad.current = false;
      document.title = "افزودن آدرس جدید";
      map.current = L.map("map").setView([currentData.x, currentData.y], 17);
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map.current);
    }
  }, []);
  function formSubmitHandler(e) {
    e.preventDefault();
    if (
      !formData.address ||
      !formData.postalCode ||
      formData.postalCode.length !== 11
    ) {
      showModal("لطفا تمام کادرهارا پر کنید.");
      return;
    }
    const data = new FormData();
    data.append("token", userInfo.tkn);
    data.append("ID", 0);
    data.append("Address", formData.address);
    data.append("ZipCode", formData.postalCode);
    data.append("points", `${formData.pointX},${formData.pointY}`);
    fetch("https://crm.bamamiresi.com/Enrollment/AddNewAddress", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        const serverData = json[0];
        if (+serverData.Code !== 200) {
          throw new Error(
            serverData.Message ? serverData.Message : "خطایی رخ داده است."
          );
        }
        showModal("آدرس با موفقیت افزوده شد");
        if (params.get("ref")) {
          navigate(`/${params.get("ref")}`);
        } else {
          navigate("/profile/myAddresses");
        }
      })
      .catch((err) => {
        showModal(err.message);
      });
  }
  return (
    <form
      method="post"
      className="flex flexCol addAddressForm"
      onSubmit={(e) => formSubmitHandler(e)}
    >
      <fieldset className="customFieldset flex flexCol">
        <p className="faFont profileAddAddressTitle flex alignCenter">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeWidth="2"
              d="M12,22 C12,22 4,16 4,10 C4,5 8,2 12,2 C16,2 20,5 20,10 C20,16 12,22 12,22 Z M12,13 C13.657,13 15,11.657 15,10 C15,8.343 13.657,7 12,7 C10.343,7 9,8.343 9,10 C9,11.657 10.343,13 12,13 L12,13 Z"
            ></path>
          </svg>
          انتخاب روی نقشه
        </p>
        <div className="flex flexCol profileMapHolder rel">
          <div id="map" style={{ height: "300px", width: "100%" }}></div>
        </div>
      </fieldset>
      <fieldset className="customFieldset flex flexCol addressFieldset">
        <label
          htmlFor="address"
          className="faFont profileAddAddressTitle flex alignCenter"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              strokeWidth="2"
              d="M17.5,6.5 L23,9 L23,22 L16,19 L8,22 L1,19 L1,6 L6,8 M16,19 L16,12 M8,22 L8,12 M12,16.2727273 C12,16.2727273 6,11.5 6,7 C6,3.25 9,1 12,1 C15,1 18,3.25 18,7 C18,11.5 12,16.2727273 12,16.2727273 Z M13,7 C13,6.44766667 12.5523333,6 12,6 C11.4476667,6 11,6.44766667 11,7 C11,7.55233333 11.4476667,8 12,8 C12.5523333,8 13,7.55233333 13,7 Z"
            ></path>
          </svg>
          آدرس
        </label>
        <textarea
          name="address"
          id="address"
          className="faFont customTextArea"
          placeholder="آدرس را وارد کنید"
          required
          autoComplete="address"
          onChange={(e) =>
            setFormData({ ...formData, address: e.target.value })
          }
        ></textarea>
      </fieldset>
      <fieldset className="customFieldset flex flexCol">
        <label
          htmlFor="postalCode"
          className="faFont profileAddAddressTitle flex alignCenter"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M17 19.22H5V7h7V5H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-7h-2v7.22z"></path>
            <path d="M19 2h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V7h3V5h-3V2zM7 9h8v2H7zM7 12v2h8v-2h-3zM7 15h8v2H7z"></path>
          </svg>
          کد پستی
        </label>
        <input
          name="postalCode"
          id="postalCode"
          type="text"
          className="faFont customInput"
          required
          placeholder="آدرس را وارد کنید"
          maxLength={11}
          autoComplete="postalcode"
          onChange={(e) =>
            setFormData({ ...formData, postalCode: e.target.value })
          }
        />
      </fieldset>
      <button className="faFont submitFormBtn flex flexCenter">ثبت آدرس</button>
    </form>
  );
}
