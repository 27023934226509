import "./App.css";
import Cookies from "js-cookie";
import { createContext, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import CoursesPage from "./Component/CoursesPage";
import Login from "./Component/Login";
import Player from "./Component/Player";
import VideoPage from "./Component/VideosPage";
import TeachersPage from "./Component/TeachersPage";
import TeacherDetails from "./Component/TeacherDetails";
import FivePage from "./Component/FivePage";
import PodcastDetailsPage from "./Component/PodcastDetailsPage";
import Modal from "./Component/Modal";
import AllPodcasts from "./Component/AllPodcasts";
import CourseDetailsPage from "./Component/CourseDetailsPage";
import ReservePage from "./Component/ReservePage";
import SchoolPage from "./Component/SchoolPage";
import VideoDetailsPage from "./Component/VideoDetailsPage";
import VideoCastDetailsPage from "./Component/VideoCastDetailsPage";
import { createId } from "./Component/Methods";
import Blogs from "./Component/Blogs";
import BlogDetails from "./Component/BlogDetails";
import About from "./Component/About";
import SearchPage from "./Component/SearchPage";
import Contact from "./Component/Contact";
import Cart from "./Component/Cart";
import Profile from "./Component/Profile";
import SearchSchool from "./Component/SearchSchool";
import AudioBook from "./Component/AudioBook";
import VideoCastPage from "./Component/VideosCastPage";
import Products from "./Component/Products";
import ProductDetailsPage from "./Component/ProductDetailsPage";
import SubmitCart from "./Component/SubmitCart";
import PayResult from "./Component/PayResult";
export const AppContext = createContext();
function App() {
  const uid = useRef(Cookies.get("tkn"));
  const [userInfo, setUserInfo] = useState({
    tkn: uid.current,
    data: null,
  });
  const [player, setPlayer] = useState(null);
  const [siteData, setSiteData] = useState(null);
  const [cartData, setCartData] = useState(["testItem1", "testItem2"]);
  return (
    <>
      <AppContext.Provider
        value={{
          userData: [userInfo, setUserInfo],
          playerInfo: [player, setPlayer],
          siteInfo: [siteData, setSiteData],
          cartContext: [cartData, setCartData],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index path="/" element={<Home />}></Route>
            <Route path="/courses" element={<CoursesPage />}></Route>
            <Route path="/packages" element={<VideoPage />}></Route>
            <Route path="/videoCasts" element={<VideoCastPage />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/teachers" element={<TeachersPage />}></Route>
            <Route path="/teacher/:id" element={<TeacherDetails />}></Route>
            <Route path="/podcasts" element={<AllPodcasts />}></Route>
            <Route path="/podcast/:id" element={<PodcastDetailsPage />}></Route>
            <Route path="/course/:id" element={<CourseDetailsPage />}></Route>
            <Route path="/505" element={<FivePage />}></Route>
            <Route path="/reserve/:id/:name" element={<ReservePage />}></Route>
            <Route path="/school/:id/" element={<SchoolPage />}></Route>
            <Route path="/package/:id/" element={<VideoDetailsPage />}></Route>
            <Route
              path="/videoCast/:id/"
              element={<VideoCastDetailsPage />}
            ></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route path="/blog/:name/" element={<BlogDetails />}></Route>
            <Route path="/aboutus" element={<About />}></Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route path="/contactus" element={<Contact />}></Route>
            <Route path="/cart" element={<Cart />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/profile/:path" element={<Profile />}></Route>
            <Route path="/profile/:path/:id" element={<Profile />}></Route>
            <Route path="/schools" element={<SearchSchool />}></Route>
            <Route path="/audiobook/:id" element={<AudioBook />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/products/:cat" element={<Products />}></Route>
            <Route path="/products/:cat/:subcat" element={<Products />}></Route>
            <Route path="/product/:id" element={<ProductDetailsPage />}></Route>
            <Route path="/product" element={<ProductDetailsPage />}></Route>
            <Route path="/submitCart/" element={<SubmitCart />}></Route>
            <Route path="/payResult/:result" element={<PayResult />}></Route>
          </Routes>
          <Modal />
          <Player />
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}
export default App;
