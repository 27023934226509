import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function ProfileHome({changeMenuHandler,getProfileNavs}){
    const navigate = useNavigate();
    const [data,setData] = useState({});
    const [firstLoading,setFirstLoading] = useState(true);
    const firstLoad = useRef(true);
    useEffect(() => {
        document.title = "پروفایل کاربر";
        if(firstLoad.current){
            firstLoad.current = false;
            fetchHomeData();
        }
    }, []);
    function fetchHomeData(){
        const data = new FormData();
         // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
        data.append("token","");
        fetch("https://crm.bamamiresi.com/Enrollment/getProfileHome",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            setFirstLoading(false);
            const serverData = json[0];
            if (serverData.code !== 200) {
                throw new Error("Error happend");
            }
            setData(serverData);
        })
        .catch((err) => {
            console.log(err);
            // navigate("/505");
            setFirstLoading(false);
            const serverRes = {
                userName : "نام کاربر",
                userPhone : "09123456789",
                classNumber : "2",
                orderNumber : "3",
                userVideos : "5"
            }
            setData(serverRes)
        });
    }
    return <>
        {
             firstLoading ? <>
                <div className="flex flexCenter profileLoading2 rel">
                    <div className="profileSubLoadingSpin"></div>
                    <div className="flex flexCol userDesktopLoading">
                        <div className="flex flexCol rel userDesktopLeftDiv">
                            <div className="loaderItem"></div>
                        </div>
                    </div>        
                </div>
            </> :
            <>
                <div className="profileHomeMainDiv flex flexCol">
                    <div className="grid profileHomeStateGrid">
                        <button className="flex flexCol profileHomeStateItem flex flexCenter" onClick={()=>changeMenuHandler("classes")}>
                            <p className="flex flexCol rel">
                                <object data="/icon/teacher.svg" type="image/svg+xml" width="60" height="60" className="profileHomeStateIcon">
                                    Your browser does not support SVGs.
                                </object>
                                <span className="faFont profileHomeStateNumber">
                                    {
                                        data && data.classNumber ? data.classNumber : "0"
                                    }
                                </span>
                            </p>
                            <p className="faFont profileHomeStateText">
                                کلاس های فعال
                            </p>
                        </button>
                        <button className="flex flexCol profileHomeStateItem flex flexCenter" onClick={()=>changeMenuHandler("orders")}>
                            <p className="flex flexCol rel">
                                <object data="/icon/basket.svg" type="image/svg+xml" width="60" height="60" className="profileHomeStateIcon">
                                    Your browser does not support SVGs.
                                </object>
                                <span className="faFont profileHomeStateNumber">
                                    {
                                        data && data.orderNumber ? data.orderNumber : "0"
                                    }
                                </span>
                            </p>
                            <p className="faFont profileHomeStateText">
                                سفارش های فعال
                            </p>
                        </button>
                        <button className="flex flexCol profileHomeStateItem flex flexCenter" onClick={()=>changeMenuHandler("videos")}>
                            <p className="flex flexCol rel">
                                <object data="/icon/video.svg" type="image/svg+xml" width="60" height="60" className="profileHomeStateIcon">
                                    Your browser does not support SVGs.
                                </object>
                                <span className="faFont profileHomeStateNumber">
                                    {
                                        data && data.userVideos ? data.userVideos : "0"
                                    }
                                </span>
                            </p>
                            <p className="faFont profileHomeStateText">
                                ویدیوها
                            </p>
                        </button>
                    </div>
                    
                    <div className="flex flexCol profileHomeNavs">
                        {
                            getProfileNavs()
                        }
                    </div>
                </div>
            </>
        }
    
    </>
    
    
}