import React, { useContext, useEffect, useRef, useState } from "react";
import "../Style/login.css";
import { scrollToTop, showModal, validateEmail, validPhone } from "./Methods";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../App";
import Cookies from "js-cookie";

export default function Login() {
  const navigate = useNavigate();
  const [checkUserError, setCheckUserError] = useState({
    result: false,
    msg: "",
  });
  const [signError, setSignError] = useState({ result: false, msg: "" });
  const [loginError, setLoginError] = useState({ result: false, msg: "" });
  const [loginCodeError, setLoginCodeError] = useState({
    result: false,
    msg: "",
  });
  const [menuType, setMenuType] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(AppContext);
  const [userInfo, setUserInfo] = userData;
  const [signInputValues, setSignInputValues] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
  });
  const [loginInputValues, setLoginInputValues] = useState({
    n1: "",
    n2: "",
    n3: "",
    n4: "",
    n5: "",
  });
  const [timer, setTimer] = useState(-1);
  const intervalRef = useRef(null);
  const [loginVisi, setLoginVisi] = useState(false);
  const [loginPass, setLoginPass] = useState("");
  useEffect(() => {
    document.title = "ثبت نام | ورود به حساب کاربری";
    scrollToTop();
    if (userInfo.data) {
      navigate("/profile");
    } else {
      setFirstLoading(false);
      setMenuType("checkUser");
    }
    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    };
  }, [userInfo]);
  useEffect(() => {
    if (timer > 0) {
      intervalRef.current = setTimeout(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    } else {
      clearTimeout(intervalRef.current);
      setTimer(0);
    }
  }, [timer]);
  function checkUserHandler(e) {
    e.preventDefault();
    if (validateEmail(userPhone) || validPhone(userPhone)) {
      setLoading(true);
      const data = new FormData();
      data.append("username", userPhone);
      data.append("token", userInfo.tkn);
      data.append("i", 0);
      fetch("https://crm.bamamiresi.com/Enrollment/getLogin", {
        method: "POST",
        body: data,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("102");
          }
          return res.json();
        })
        .then((json) => {
          if (json.error) {
            throw new Error(json.msg);
          }
          let result = json[0];
          if (result) {
            if (+result.code === 1) {
              showModal("کد تایید برای شما ارسال شد.");
              setMenuType("sign");
              setLoading(false);
              setTimer(+result.resendTime);
            } else if (+result.code === 0) {
              setMenuType("login");
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
        });
    } else {
      setCheckUserError({
        result: true,
        msg: "شماره موبایل یا ایمیل صحیح نمی باشد.",
      });
      showModal("شماره موبایل یا ایمیل صحیح نمی باشد.");
    }
  }
  function getCheckUserContent() {
    return (
      <form
        className="flex flexCol checkUserForm"
        method="post"
        onSubmit={(event) => checkUserHandler(event)}
      >
        <img
          src="/image/logo.webp"
          alt="bamamiresi logo"
          className="loginImg"
          draggable="false"
          width={150}
        />
        <h1 className="faFont textSize1 textClr3 loginTitle">ورود | ثبت نام</h1>
        <p className="faFont textSize9 textClr3 loginText1">
          لطفا شماره موبایل یا ایمیل خود را وارد کنید
        </p>
        <input
          dir="ltr"
          type="text"
          className={
            checkUserError.result
              ? "faFont loginFirstCheckInput error"
              : "faFont loginFirstCheckInput"
          }
          id="userPhone"
          required
          autoComplete="phone"
          name="phone"
          placeholder="شماره خود را اینجا وارد کنید"
          value={userPhone}
          onChange={(event) => setUserPhone(event.target.value)}
          maxLength={11}
        />
        <p
          className={`faFont textSize9 userCheckHintText ${
            checkUserError.result ? "show" : ""
          }`}
        >
          {checkUserError.msg}
        </p>
        <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
          تایید
        </button>
      </form>
    );
  }
  function signCodeChangeHandler(e) {
    if (
      e.code.toLowerCase() === "delete" ||
      e.code.toLowerCase() === "backspace"
    ) {
      return;
    }
    if (
      signInputValues.n1 !== "" &&
      signInputValues.n2 !== "" &&
      signInputValues.n3 !== "" &&
      signInputValues.n4 !== "" &&
      signInputValues.n5 !== ""
    ) {
      signFormSubmitHandler();
    } else {
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
      }
    }
  }
  function signFormSubmitHandler(e) {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const data = new FormData();
    data.append("username", userPhone);
    data.append("deviceInfo", "");
    data.append("token", userInfo.tkn);
    data.append("i", 0);
    data.append(
      "code",
      `${signInputValues.n1}${signInputValues.n2}${signInputValues.n3}${signInputValues.n4}${signInputValues.n5}`
    );
    fetch("https://crm.bamamiresi.com/Enrollment/loginWithOneUseCode", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        let result = json[0];
        if (result) {
          setLoading(false);
          if (+result.code === 404) {
            setSignError({ result: true, msg: "کد وارد شده اشتباه است." });
            showModal("کد وارد شده اشتباه است.");
          } else if (+result.code === 200) {
            let newToken = result.token;
            Cookies.set("tkn", newToken, {
              expires: 60,
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
      });
  }
  function resendSignCodeRequest() {
    setLoading(true);
    const data = new FormData();
    data.append("username", userPhone);
    data.append("token", userInfo.tkn);
    fetch("https://crm.bamamiresi.com/Enrollment/reSendOneUseCode", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        let result = json[0];
        setLoading(false);
        if (result) {
          if (result.code == "500") {
            showModal("کد وارد شده اشتباه است.");
          } else {
            setTimer(120);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
      });
  }
  function getSignContent() {
    return (
      <form
        method="post"
        className="flex flexCol signForm"
        onSubmit={(e) => signFormSubmitHandler(e)}
      >
        <button
          className="flex flexCenter backBtn"
          onClick={() => setMenuType("checkUser")}
          type="button"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <img
          src="/image/logo.webp"
          alt="bamamiresi logo"
          className="loginImg"
          draggable="false"
          width={150}
        />
        <h1 className="faFont textSize1 textClr3 loginTitle">
          کد تایید را وارد کنید.
        </h1>
        <p className="faFont textSize9 textClr3 loginText1">
          کد تایید برای {userPhone} ارسال شد.
        </p>
        <div className="flex align signCodeInputDiv">
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="signCode1"
            value={signInputValues.n1}
            onChange={(e) => {
              setSignInputValues({ ...signInputValues, n1: e.target.value });
            }}
            autoComplete="off"
            onKeyUp={(e) => signCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="signCode2"
            value={signInputValues.n2}
            onChange={(e) => {
              setSignInputValues({ ...signInputValues, n2: e.target.value });
            }}
            autoComplete="off"
            onKeyUp={(e) => signCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="signCode3"
            value={signInputValues.n3}
            onChange={(e) => {
              setSignInputValues({ ...signInputValues, n3: e.target.value });
            }}
            autoComplete="off"
            onKeyUp={(e) => signCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="signCode4"
            value={signInputValues.n4}
            onChange={(e) => {
              setSignInputValues({ ...signInputValues, n4: e.target.value });
            }}
            autoComplete="off"
            onKeyUp={(e) => signCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="signCode5"
            value={signInputValues.n5}
            onChange={(e) => {
              setSignInputValues({ ...signInputValues, n5: e.target.value });
            }}
            autoComplete="off"
            onKeyUp={(e) => signCodeChangeHandler(e)}
          />
        </div>
        <p
          className={`faFont textSize9 userCheckHintText ${
            signError.result ? "show" : ""
          }`}
        >
          {signError.msg}
        </p>
        <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
          تایید
        </button>
        <div className="flex alignCenter justifyBetween formFooterActionDiv">
          <button
            className="faFont flex alignCenter gap5 formBackToNumber"
            onClick={() => setMenuType("checkUser")}
          >
            تغییر شماره
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
            </svg>
          </button>
          {timer > 0 ? (
            <p className="faFont timerText">{formatTime(timer)}</p>
          ) : (
            <button
              className="faFont requestCodeAgainBtn"
              type="button"
              onClick={() => resendSignCodeRequest()}
            >
              درخواست مجدد کد
            </button>
          )}
        </div>
      </form>
    );
  }
  function resnedLoggedCode() {
    setLoading(true);
    const data = new FormData();
    data.append("username", userPhone);
    data.append("token", userInfo.tkn);
    data.append("i", 0);
    fetch("https://crm.bamamiresi.com/Enrollment/reSendOneUseCode", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        let result = json[0];
        console.log(result);
        if (result) {
          setLoading(false);
          if (+result.code === 200) {
            showModal("کد تایید برای شما ارسال شد.");
            setMenuType("loginWithCode");
            setTimer(+result.resendTime);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
      });
  }
  function loginWithCodeFormHandler(e) {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    const data = new FormData();
    data.append("username", userPhone);
    data.append("token", userInfo.tkn);
    data.append("i", 0);
    data.append(
      "code",
      `${loginInputValues.n1}${loginInputValues.n2}${loginInputValues.n3}${loginInputValues.n4}${loginInputValues.n5}`
    );
    fetch("https://crm.bamamiresi.com/Enrollment/loginWithOneUseCode", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        let result = json[0];
        if (result) {
          setLoading(false);
          if (+result.code === 404) {
            setLoginInputValues({ n1: "", n2: "", n3: "", n4: "", n5: "" });
            setLoginCodeError({ result: true, msg: "کد وارد شده اشتباه است." });
            showModal("کد وارد شده اشتباه است.");
          } else {
            let newToken = result.token;
            Cookies.set("tkn", newToken, {
              expires: 60,
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            window.location.href = "/";
            showModal("ورود با موفقیت انجام شد.");
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
      });
  }
  function getLoginWithCodeContent() {
    return (
      <form
        method="post"
        className="flex flexCol signForm"
        onSubmit={(e) => loginWithCodeFormHandler(e)}
      >
        <button
          className="flex flexCenter backBtn"
          onClick={() => setMenuType("checkUser")}
          type="button"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <img
          src="/image/logo.webp"
          alt="bamamiresi logo"
          className="loginImg"
          draggable="false"
          width={150}
        />
        <h1 className="faFont textSize1 textClr3 loginTitle">
          کد تایید را وارد کنید.
        </h1>
        <p className="faFont textSize9 textClr3 loginText1">
          کد تایید برای {userPhone} ارسال شد.
        </p>
        <div className="flex align signCodeInputDiv">
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="loginCode1"
            value={loginInputValues.n1}
            onChange={(e) => {
              setLoginInputValues({
                ...loginInputValues,
                n1: e.target.value,
              });
            }}
            autoComplete="off"
            onKeyUp={(e) => loginCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="loginCode2"
            value={loginInputValues.n2}
            onChange={(e) => {
              setLoginInputValues({
                ...loginInputValues,
                n2: e.target.value,
              });
            }}
            autoComplete="off"
            onKeyUp={(e) => loginCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="loginCode3"
            value={loginInputValues.n3}
            onChange={(e) => {
              setLoginInputValues({
                ...loginInputValues,
                n3: e.target.value,
              });
            }}
            autoComplete="off"
            onKeyUp={(e) => loginCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="loginCode4"
            value={loginInputValues.n4}
            onChange={(e) => {
              setLoginInputValues({
                ...loginInputValues,
                n4: e.target.value,
              });
            }}
            autoComplete="off"
            onKeyUp={(e) => loginCodeChangeHandler(e)}
          />
          <input
            type="text"
            maxLength="1"
            className="signCodeInput faFont textSize9 textClr3 flex flexCenter"
            required
            name="loginCode5"
            value={loginInputValues.n5}
            onChange={(e) => {
              setLoginInputValues({
                ...loginInputValues,
                n5: e.target.value,
              });
            }}
            autoComplete="off"
            onKeyUp={(e) => loginCodeChangeHandler(e)}
          />
        </div>
        <p
          className={`faFont textSize9 userCheckHintText ${
            loginCodeError.result ? "show" : ""
          }`}
        >
          {loginCodeError.msg}
        </p>
        <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
          تایید
        </button>
        <div className="flex alignCenter justifyBetween formFooterActionDiv">
          <span></span>
          {timer > 0 ? (
            <p className="faFont timerText">{formatTime(timer)}</p>
          ) : (
            <button
              className="faFont requestCodeAgainBtn"
              type="button"
              onClick={() => resendSignCodeRequest()}
            >
              درخواست مجدد کد
            </button>
          )}
        </div>
      </form>
    );
  }
  function loginFormHandler(e) {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    data.append("username", userPhone);
    data.append("token", userInfo.tkn);
    data.append("password", loginPass);
    data.append("i", 0);
    fetch("https://crm.bamamiresi.com/Enrollment/loginWithPassword", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        let result = json[0];
        if (result) {
          setLoading(false);
          if (+result.code === 0) {
            setLoginError({ result: true, msg: result.message });
            showModal(result.message);
          } else {
            let newToken = result.token;
            Cookies.set("tkn", newToken, {
              expires: 60,
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            window.location.href = "/";
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        showModal("عدم ارتباط با سرور .لطفا دوباره تلاش کنید.");
      });
  }
  function getLoginContent() {
    return (
      <form
        method="post"
        className="flex flexCol loginForm"
        onSubmit={(e) => loginFormHandler(e)}
      >
        <button
          className="flex flexCenter backBtn"
          onClick={() => setMenuType("checkUser")}
          type="button"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"></path>
          </svg>
        </button>
        <img
          src="/image/logo.webp"
          alt="bamamiresi logo"
          className="loginImg"
          draggable="false"
          width={150}
        />
        <h1 className="faFont textSize1 textClr3 loginTitle">
          ورود به حساب کاربری
        </h1>
        <fieldset className="flex flexCol gap5 mar10 rel">
          <label htmlFor="loginPass" className="faFont textSize9 textClr7">
            کلمه عبور خود را وارد کنید.
          </label>
          <input
            type={loginVisi ? "text" : "password"}
            id="loginPass"
            className="faFont textSize1 textClr3 customFormInput login"
            name="loginPass"
            placeholder="کلمه عبور"
            required
            value={loginPass}
            onChange={(e) => setLoginPass(e.target.value)}
          />
          <button
            className="toggleLoginPassBtn flex flexCenter textSize1 textClr5"
            onClick={() => setLoginVisi(!loginVisi)}
            type="button"
          >
            {loginVisi ? (
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="none"
                  d="M0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0zm0 0h24v24H0V0z"
                ></path>
                <path d="M12 6a9.77 9.77 0 0 1 8.82 5.5 9.647 9.647 0 0 1-2.41 3.12l1.41 1.41c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l1.65 1.65C10.66 6.09 11.32 6 12 6zm-1.07 1.14L13 9.21c.57.25 1.03.71 1.28 1.28l2.07 2.07c.08-.34.14-.7.14-1.07C16.5 9.01 14.48 7 12 7c-.37 0-.72.05-1.07.14zM2.01 3.87l2.68 2.68A11.738 11.738 0 0 0 1 11.5C2.73 15.89 7 19 12 19c1.52 0 2.98-.29 4.32-.82l3.42 3.42 1.41-1.41L3.42 2.45 2.01 3.87zm7.5 7.5 2.61 2.61c-.04.01-.08.02-.12.02a2.5 2.5 0 0 1-2.5-2.5c0-.05.01-.08.01-.13zm-3.4-3.4 1.75 1.75a4.6 4.6 0 0 0-.36 1.78 4.507 4.507 0 0 0 6.27 4.14l.98.98c-.88.24-1.8.38-2.75.38a9.77 9.77 0 0 1-8.82-5.5c.7-1.43 1.72-2.61 2.93-3.53z"></path>
              </svg>
            ) : (
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                height="18"
                width="18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z"></path>
                <path d="M12 6a9.77 9.77 0 0 1 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5A9.77 9.77 0 0 1 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"></path>
              </svg>
            )}
            <span className="hideText">togglePass</span>
          </button>
        </fieldset>
        <p
          className={`faFont textSize9 userCheckHintText ${
            loginError.result ? "show" : ""
          }`}
        >
          {loginError.msg}
        </p>
        <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
          تایید
        </button>
        <div className="flex formBtnDiv">
          <button
            className="faFont textSize9 textClr5"
            type="button"
            onClick={() => resnedLoggedCode()}
          >
            ورود با رمز یکبار مصرف
          </button>
        </div>
      </form>
    );
  }
  function loginCodeChangeHandler(e) {
    if (
      e.code.toLowerCase() === "delete" ||
      e.code.toLowerCase() === "backspace"
    ) {
      return;
    }
    if (
      loginInputValues.n1 !== "" &&
      loginInputValues.n2 !== "" &&
      loginInputValues.n3 !== "" &&
      loginInputValues.n4 !== "" &&
      loginInputValues.n5 !== ""
    ) {
      loginWithCodeFormHandler();
    } else {
      if (e.target.nextElementSibling) {
        e.target.nextElementSibling.focus();
      }
    }
  }
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const remainingSeconds = time % 60;

    if (hours > 0) {
      return `${hours}:${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
      ).padStart(2, "0")}`;
    } else {
      return `${minutes}:${String(remainingSeconds).padStart(2, "0")}`;
    }
  };
  return (
    <section className="loginMainDiv flex flexCol container rel">
      {menuType === "checkUser" && getCheckUserContent()}
      {menuType === "sign" && getSignContent()}
      {menuType === "login" && getLoginContent()}
      {menuType === "loginWithCode" && getLoginWithCodeContent()}
      {firstLoading && (
        <div className="loginLoading flex flexCol flexCenter container">
          <div className="signLoadingSpin"></div>
        </div>
      )}
      {loading && (
        <div className="flex flexCol flexCenter loadingProgressMainDiv">
          <div className="flex flexCol flexCenter loadingMoreDataSubDiv">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="12" y1="2" x2="12" y2="6"></line>
              <line x1="12" y1="18" x2="12" y2="22"></line>
              <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
              <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
              <line x1="2" y1="12" x2="6" y2="12"></line>
              <line x1="18" y1="12" x2="22" y2="12"></line>
              <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
              <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
          </div>
        </div>
      )}
    </section>
  );
}
