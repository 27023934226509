import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "../Style/profileVideos.css";
import { scrollToTop, showModal } from "./Methods";
import EmptyResult from "./EmptyResult";
import AlertMenu from "./AlertMenu";
import ProfileSubLoading from "./ProfileSubLoading";
import PagePagintaion from "./PagePagination";
export default function ProfileVideos({ changeMenuHandler }) {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [firstLoading, setFirstLoading] = useState(true);
  const firstLoad = useRef(true);
  const [searchParam] = useSearchParams();
  const pageNumber = useRef(1);
  const [toggleAlert, setToggleAlert] = useState({ id: "", show: false });
  const [progressLoading, setProgressLoading] = useState(false);
  useEffect(() => {
    document.title = "ویدیوهای من";
    if (firstLoad.current) {
      firstLoad.current = false;
      fetchVideosData();
    }
  }, []);
  function fetchVideosData() {
    const data = new FormData();
    pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
    // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
    data.append("pageCount", 10);
    data.append("pageIndex", pageNumber.current);
    data.append("token", "");
    fetch("https://crm.bamamiresi.com/Enrollment/getProfileVideos", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        setFirstLoading(false);
        const serverData = json[0];
        if (serverData.code !== 200) {
          throw new Error("Error happend");
        }
        setData(serverData);
      })
      .catch((err) => {
        console.log(err);
        scrollToTop();
        // navigate("/505");
        setFirstLoading(false);
        const serverRes = {
          videos: [
            {
              id: 5414618718,
              name: "آموزش پیانو",
              cover: "/image/blogCover.jpg",
              state: 1,
              // منتشر شده
            },
            {
              id: 9987179,
              name: "آموزش گیتار الکترونیک",
              cover: "/image/mic.jpg",
              state: 0,
              // در حال بررسی
            },
          ],
          totalPage: 5,
          loading: false,
        };
        setData(serverRes);
      });
  }
  function confirmCancelMenu() {
    setProgressLoading(true);
    setToggleAlert({ ...toggleAlert, show: false });
    requestForDeleteVideo(toggleAlert.id);
  }
  function requestForDeleteVideo(videoId) {
    const data = new FormData();
    pageNumber.current = searchParam.get("p") ? searchParam.get("p") : 1;
    // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
    data.append("videoId", videoId);
    data.append("pageCount", 10);
    data.append("pageIndex", pageNumber.current);
    data.append("token", "");
    fetch("https://crm.bamamiresi.com/Enrollment/requestDeleteVideo", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        setFirstLoading(false);
        const serverData = json[0];
        if (serverData.code !== 200) {
          throw new Error("Error happend");
        }
        setData(serverData);
      })
      .catch((err) => {
        console.log(err);
        // navigate("/505");
        setFirstLoading(false);
        const serverRes = {
          videos: [
            {
              id: 5414618718,
              name: "آموزش پیانو",
              cover: "/image/blogCover.jpg",
              state: 1,
              // منتشر شده
            },
            {
              id: 9987179,
              name: "آموزش گیتار الکترونیک",
              cover: "/image/mic.jpg",
              state: 0,
              // در حال بررسی
            },
          ],
          totalPage: 5,
          loading: false,
          result: true,
          msg: "ویدیو با موفقیت حذف شد.",
        };
        if (serverRes.result) {
          setData(serverRes);
          scrollToTop();
        }
        setProgressLoading(false);
        setToggleAlert({ id: "", show: false });
        showModal(serverRes.msg);
      });
  }
  function changePage(newPageNumber) {
    scrollToTop();
    setData({
      ...data,
      loading: true,
    });
    searchParam.set("p", newPageNumber);
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParam}`
    );
    fetchVideosData();
  }
  return (
    <>
      {firstLoading ? (
        <>
          <div className="flex flexCenter profileLoading2 rel">
            <div className="profileSubLoadingSpin"></div>
            <div className="flex flexCol userDesktopLoading">
              <div className="flex flexCol rel userDesktopLeftDiv">
                <div className="loaderItem"></div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <section className="flex flexCol profileVideosSection">
            <div className="flex alignCenter justifyBetween profileVideoHeaderDiv">
              <h2 className="faFont profileHeaderText flex alignCenter">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="21"
                  width="21"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M9 7v8l7-4zm12-4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 14H3V5h18v12z"></path>
                </svg>
                ویدیوهای من
              </h2>
              <button
                className="uploadVideoBtn faFont flex alignCetner gap5"
                onClick={() => changeMenuHandler("upload")}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  height="17"
                  width="17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z"></path>
                </svg>
                ویدیوی جدید
              </button>
            </div>
            <div className="grid profileVideosGrid">
              {data && data.videos.length > 0 ? (
                <>
                  {data.videos.map((item, index) => {
                    return (
                      <div
                        className="flex flexCol profileVideosItem"
                        key={index}
                      >
                        <Link
                          className="flex flexCol profileVideosHolderVideoDiv"
                          to={`/videoCast/${item.id}`}
                        >
                          <img
                            src={item.cover}
                            alt={item.name}
                            className="profileVidoesImg"
                            draggable="false"
                            height={"100%"}
                            width={"100%"}
                          />
                          <p className="flex flexCenter playLogoP">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 384 512"
                              height="25"
                              width="25"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path>
                            </svg>
                          </p>
                        </Link>
                        <p className="faFont profileVidoesItemName">
                          {item.name}
                        </p>
                        <div className="flex profileVidoesActionDiv">
                          <p
                            className={`faFont profileVideoItemState ${
                              +item.state === 0 ? "pending" : "published"
                            }`}
                          >
                            {+item.state === 0 ? (
                              <>
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="17"
                                  width="17"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M20.942 13.021a9 9 0 1 0 -9.407 7.967"></path>
                                  <path d="M12 7v5l3 3"></path>
                                  <path d="M15 19l2 2l4 -4"></path>
                                </svg>
                                در انتظار تایید
                              </>
                            ) : (
                              <>
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 24 24"
                                  height="18"
                                  width="18"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path>
                                </svg>
                                منتشر شده
                              </>
                            )}
                          </p>
                          <div className="flex alignCenter gap5">
                            <button
                              className="flex flexCenter profileVideosRemoveBtn"
                              onClick={() =>
                                setToggleAlert({ id: item.id, show: true })
                              }
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 256 256"
                                height="17"
                                width="17"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z"
                                  opacity="0.2"
                                ></path>
                                <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                              </svg>
                              <span className="hideText">remove</span>
                            </button>
                            <Link
                              className="flex flexCenter profileVideosSeeBtn"
                              to={`/videoCast/${item.id}`}
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="17"
                                width="17"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                              </svg>
                              <span className="hideText">itemNmae</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <EmptyResult
                  linkAddres={"/profile/upload"}
                  linkText={"آپلود ویدیوی جدید"}
                  text={"ویدیویی یافت نشد."}
                />
              )}
            </div>
            <PagePagintaion
              data={{
                totalNumber: data.totalPage,
                changePage: changePage,
                currentPage: pageNumber,
              }}
            />
            {progressLoading && <ProfileSubLoading />}
            {data.loading && <ProfileSubLoading />}
          </section>
          {toggleAlert.show && (
            <AlertMenu
              data={{
                title: "حذف ویدیو",
                toggleAlert: toggleAlert,
                setToggleAlert: setToggleAlert,
                confirm: confirmCancelMenu,
                content: "برای حذف ویدیو بر روی دکمه تایید کلیک کنید.",
              }}
            />
          )}
        </>
      )}
    </>
  );
}
