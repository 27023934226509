import React from "react";
import {ReactComponent as ArrowLeft} from "../icon/arrowLeft.svg";
import {ReactComponent as ArrowRight} from "../icon/arrowRight.svg";
import "../Style/pagePagination.css";
export default function PagePagintaion(props){
    const data = props.data;
    return <>
        {
            data.totalNumber > 1 && <div className="flex flexCenter paginationDiv">
            {
                +data.currentPage.current > 1 && <button className="prePage flex flexCenter" onClick={()=>data.changePage(+data.currentPage.current - 1)}>
                    <ArrowLeft />
                </button>
            }
            {
                +data.currentPage.current > 2 && <button className="pageItem flex flexCenter faFont textSize1 textClr3" onClick={()=>data.changePage(+data.currentPage.current - 2)}>
                    {
                        +data.currentPage.current - 2
                    }
                </button>
            }
            {
                +data.currentPage.current > 1 && <button className="pageItem flex flexCenter faFont textSize1 textClr3" onClick={()=>data.changePage(+data.currentPage.current - 1)}>
                    {
                        +data.currentPage.current - 1
                    }
                </button>
            }
            {
                data.currentPage.current && <button className="flex flexCenter faFont textSize1 textClr3 currentPage">
                    {
                        +data.currentPage.current
                    }
                </button>
            }
            {
                +data.currentPage.current + 1 <= +data.totalNumber && <button className="pageItem flex flexCenter faFont textSize1 textClr3" onClick={()=>data.changePage(+data.currentPage.current + 1)}>
                    {
                        +data.currentPage.current + 1
                    }
                </button>
            }
            {
                +data.currentPage.current + 2 <= +data.totalNumber && <button className="pageItem flex flexCenter faFont textSize1 textClr3" onClick={()=>data.changePage(+data.currentPage.current + 2)}>
                    {
                        +data.currentPage.current + 2
                    }
                </button>
            }
            {
                +data.currentPage.current + 3 <= +data.totalNumber && <button className="pageItem flex flexCenter faFont textSize1 textClr3" onClick={()=>data.changePage(+data.currentPage.current + 3)}>
                    {
                        +data.currentPage.current + 3
                    }
                </button>
            }
            {
                +data.currentPage.current + 1 <= +data.totalNumber && <button className="nextPage flex flexCenter" onClick={()=>data.changePage(+data.currentPage.current + 1)}>
                    <ArrowRight />
                </button>
            }
        </div>
        }
    </>
}