import React, { useState } from "react";
export default function CustomImage({ data }) {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [activeImg, setActiveImg] = useState(data);
  const handleError = () => {
    setActiveImg({ ...activeImg, src: "/image/defaultImage.png" });
    setImgLoaded(true);
  };

  return (
    <>
      <img
        src={activeImg.src}
        alt={activeImg.alt}
        draggable="false"
        className={activeImg.class}
        width={activeImg.width}
        height={activeImg.height}
        onLoad={() => setImgLoaded(true)}
        onError={handleError}
      />
    </>
  );
}
