import React, { useEffect, useRef, useState } from "react";
import "../Style/profileUpload.css";
import { scrollToTop, showModal } from "./Methods";
import { useNavigate } from "react-router-dom";

export default function ProfileUpload(){
    const navigate = useNavigate();
    const [data,setData] = useState();
    const [firstLoading,setFirstLoading] = useState(true);
    const firstLoad = useRef(true);
    const xhr = useRef(null);
    const uploadWave1 = useRef(null);
    const uploadWave2 = useRef(null);
    const [uploadInfo,setUploadInfo] = useState({
        loading:false,
        file : null,
        fileName : "برای انتخاب فایل کلیک کنید یا فایل مورد نظر را بر روی کادر بکشید.",
        tagsList:[],
        name : "",
        desc : "",
        select : "",
    });
    const [errors ,setErrors] = useState({
        file: null,
        name: null ,
        desc: null,
        select: null,
        tags: null,
    });
    const tagInput = useRef(null);
    const [uploadingMode ,setUploadingMode] = useState({
        active : "form",
        percent : 0,
    })
    useEffect(() => {
        if(firstLoad.current){
            firstLoad.current = false;
            fetchUploadData();
        }
    }, []);
    function fetchUploadData(){
        const data = new FormData();
        // data.append("token",userInfo.tkn ? userInfo.tkn : -1);
        data.append("token","");
        fetch("https://crm.bamamiresi.com/Enrollment/getUploadInfo",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            setFirstLoading(false);
            const serverData = json[0];
            if (serverData.code !== 200) {
                throw new Error("Error happend");
            }
            setData(serverData);
        })
        .catch((err) => {
            scrollToTop();
            console.log(err);
            // navigate("/505");
            setFirstLoading(false);
            const serverRes = {
                uploadToken : "",
                categoryList : [
                    {
                        name : "دسته بندی یک",
                        value : 1
                    },
                    {
                        name : "دسته بندی ۲",
                        value : 2
                    },
                    {
                        name : "دسته بندی ۳",
                        value : 3
                    },
                    {
                        name : "دسته بندی چهار",
                        value : 4
                    },
                ]
            }
            setData(serverRes);
        });
    }
    function formSubmitHandler(e){
        e.preventDefault();
    }
    function submitFormBtn(){
        if(!uploadWave1.current){
            uploadWave1.current = document.querySelector(".uploadWave1");
        }
        if(!uploadWave2.current){
            uploadWave2.current = document.querySelector(".uploadWave2");
        }
        let hasError = false;
        let tempError = {...errors};
        if(!uploadInfo.select){
            tempError.select = "یک دسته بندی برای ویدیو انتخاب کنید.";
            hasError = true;
        }
        if(!uploadInfo.file){
            tempError.file = "فایل ویدیو را انتخاب کنید.";
            hasError = true;
        }
        if(!uploadInfo.name){
            tempError.name = "نام ویدیو را وارد کنید.";
            hasError = true;
        }
        if(!uploadInfo.desc){
            tempError.desc = "توضیحات ویدیو را وارد کنید.";
            hasError = true;
        }
        if(uploadInfo.tagsList.length === 0){
            tempError.tags = "یک هشتگ برای ویدیو وارد کنید.";
            hasError = true;
        }
        if(hasError){
            setErrors(tempError);
            scrollToTop();
            showModal("لطفا تمامی کادر هار پر کنید.");
            return;
        }
        scrollToTop();
        setUploadingMode({
            active:"progress",
            percent : 0
        });
        xhr.current = new XMLHttpRequest();
        const finalData = new FormData();
        finalData.append("video", uploadInfo.file);
        finalData.append("category", uploadInfo.select);
        finalData.append("name", uploadInfo.name);
        finalData.append("desc", uploadInfo.desc);
        finalData.append("tags", uploadInfo.tagsList.join("-,-"));
        finalData.append("tkn","token");
        xhr.current.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                const percentComplete = Math.round((event.loaded / event.total) * 100);
                setUploadingMode({active:"progress",percent : percentComplete});
                if(uploadWave1.current){
                    uploadWave1.current.style.transform = `translateY(-${percentComplete * 200 / 100}px)`;         
                }
                if(uploadWave2.current){
                    uploadWave2.current.style.transform = `translateY(-${percentComplete * 200 / 100}px)`;         
                }
            }
        });
        xhr.current.addEventListener("load", () => {
            if (xhr.current.status === 200) {
                const response = JSON.parse(xhr.current.responseText);
                if(response.error){
                    setUploadingMode({
                        active:"form",
                        percent : 0
                    });
                    showModal(response.msg);
                }else{
                    showModal("اپلود با موفقیت انجام شد.");
                    navigate("/profile/videos");
                }
            } else {
                showModal("عدم اتصال به سرور");
                setUploadingMode({
                    active:"form",
                    percent : 0
                });
            }
        });
        xhr.current.open("POST", "http://localhost/upload/index.php");
        xhr.current.send(finalData);
    }
    function checkFileListener(e){
        const input = e.target;
        const files = input.files;
        const file = files[0];
        if (file) {
            let fileSize = file.size / 1024;
            if(fileSize > 200000){
                showModal("حجم ویدیو نباید بیشتر از ۲۰۰ مگابایت باشد.");
                setErrors({...errors,file:"حجم ویدیو باید کمتر از ۲۰۰ مگابایت باشد."});
                setUploadInfo({...uploadInfo,file : null,fileName :"برای انتخاب فایل کلیک کنید یا فایل مورد نظر را بر روی کادر بکشید."});
                return;
            }
            let isVideo = file.type.startsWith('video') ? true : false ;
            if(!isVideo){
                showModal("فرمت فایل انتخابی اشتباه است.");
                setErrors({...errors,file:"فرمت فایل انتخابی اشتباه است.."});
                setUploadInfo({...uploadInfo,file : null,fileName :"برای انتخاب فایل کلیک کنید یا فایل مورد نظر را بر روی کادر بکشید."});
                return;
            }
            let fileName = input.value.split("\\");
            fileName = fileName[fileName.length - 1];
            setErrors({...errors,file:null});
            setUploadInfo({...uploadInfo,file : file,fileName :fileName });
        } else {
            if(uploadInfo.file){
                return;
            }
            setUploadInfo({...uploadInfo,file : null,fileName :"برای انتخاب فایل کلیک کنید یا فایل مورد نظر را بر روی کادر بکشید."});
            showModal("خطا در خواندن ویدیو");
        }
    }
    function addTagListener(e){
        e && e.preventDefault();
        if(!tagInput.current){
            tagInput.current = document.querySelector(".tagInput");
        }
        const tempTagList = [...uploadInfo.tagsList];
        let search = tempTagList.filter(item=>{
            return item === tagInput.current.value;
        })
        if(search.length > 0){
            showModal("هشتگ نمیتواد تکراری باشد.");
            tagInput.current.focus();
            return;
        }
        tempTagList.push(tagInput.current.value);
        tagInput.current.value = "";
        setUploadInfo({...uploadInfo,tagsList : tempTagList});
        setErrors({...errors,tags:null});
        tagInput.current.focus();
    }
    function checkInput(value,key){
        if(!value){
            setErrors({...errors,[key]:"پر کردن این کادر الزامی است."});
        }else{
            setErrors({...errors,[key]:""});
        }
    }
    function removeTagListener(removeTag){
        let newTagList = uploadInfo.tagsList.filter(item=>{
            return item !== removeTag;
        });
        setUploadInfo({...uploadInfo,tagsList :newTagList});
    }
    // xhr.abort();
    return <>
        {
            firstLoading ? <>
                <div className="flex flexCenter profileLoading2 rel">
                    <div className="profileSubLoadingSpin"></div>
                    <div className="flex flexCol userDesktopLoading">
                        <div className="flex flexCol rel userDesktopLeftDiv">
                            <div className="loaderItem"></div>
                        </div>
                    </div>          
                </div>
            </> :
            <div className="flex flexCol rel">
                <form method="post" className={`flex flexCol uploadVideoForm ${uploadingMode.active === "form" ? "showForm" : ""}`} onSubmit={(e)=>formSubmitHandler(e)}>
                    <h1 className="faFont uploadTitle flex alignCenter gap5">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path></svg>
                        افزودن ویدیو جدید
                    </h1>
                    <div className="profileUploadFormGrid grid">
                        <div className="flex flexCol gap5 profileUploadHolderDiv">
                            <fieldset className={`flex flexCol customFieldset uploadFieldset rel ${uploadInfo.file ? "done" : ""} ${errors.file ? "errorField" : "" }`}>
                                <div className="flex flexCol rel">
                                    <object data="/icon/upload.svg" type="image/svg+xml" width="60" height="60" className="profileUploadIcon">
                                        Your browser does not support SVGs.
                                    </object>
                                    <object data="/icon/uploadEmpty.svg" type="image/svg+xml" width="60" height="60" className={`profileUploadEmptyIcon ${uploadInfo.file ? "showIcon" : ""}`}>
                                        Your browser does not support SVGs.
                                    </object>
                                </div>
                                <p className="faFont profileUploadText">
                                {
                                    uploadInfo.fileName
                                }
                                </p>
                                {
                                    !uploadInfo.file && <span className="faFont profileUploadSizeText">
                                        حداکثر حجم مجاز ۲۰۰ مگابایت می باشد.
                                    </span>
                                }
                                <input type="file" name="uploadFile" id="uploadFile" className="uploadFileInput" onChange={(e)=>checkFileListener(e)} accept="video/*"/>
                            </fieldset>
                            {
                                errors.file && <p className="faFont profileErrorText">
                                    {
                                        errors.file
                                    }
                                </p>
                            }
                        </div>
                        <div className="flex flexCol profileUploadInputsDiv">
                            <fieldset className="flex flexCol customFieldset">
                                <label htmlFor="filename" className="faFont customLabel">
                                    نام ویدیو
                                </label>
                                <input type="text" name="filename" id="filename" className={`faFont customInput ${errors.name ? "errorField" : ""}`} placeholder="نام ویدیو را وارد کنید." onChange={(e)=>setUploadInfo({...uploadInfo,name:e.target.value})} onBlur={(e)=>checkInput(e.target.value ,"name")} autoComplete="off"/>
                                {
                                    errors.name && <p className="faFont profileErrorText">
                                        {
                                            errors.name
                                        }
                                    </p>
                                }
                            </fieldset>
                            <fieldset className="flex flexCol customFieldset">
                                <label htmlFor="description" className="faFont customLabel">
                                    توضیحات
                                </label>
                                <textarea name="description" id="description" className={`faFont customTextArea ${errors.desc ? "errorField" : ""}`} placeholder="توضیحات ویدیو را وارد کنید." onChange={(e)=>setUploadInfo({...uploadInfo,desc:e.target.value})} onBlur={(e)=>checkInput(e.target.value ,"desc")} autoComplete="off"></textarea>
                                {
                                    errors.desc && <p className="faFont profileErrorText">
                                        {
                                            errors.desc
                                        }
                                    </p>
                                }
                            </fieldset>
                            <fieldset className="flex flexCol customFieldset">
                                <label htmlFor="category" className="faFont customLabel">
                                    دسته بندی
                                </label>
                                <select name="category" id="category" className={`customSelect faFont ${ errors.select ? "errorField" : ""} ${errors.select ? "errorField" : ""}`} onChange={(e)=>{setUploadInfo({...uploadInfo,select:e.target.value}); checkInput(e.target.value ,"select"); }}>
                                        <option>انتخاب کنید</option>
                                    {
                                        data.categoryList && data.categoryList.map((item,index)=>{
                                            return <option className="faFont" value={item.value} key={index}>{item.name}</option>
                                        })
                                    }
                                </select>
                                {
                                    errors.select && <p className="faFont profileErrorText">
                                        {
                                            errors.select
                                        }
                                    </p>
                                }
                            </fieldset>
                        </div>
                    </div>
                    <div className="flex flexCol hashTagDiv">
                        <fieldset className={`flex flexCol customFieldset rel ${errors.tags ? "errorField" : ""}`}>
                            <label htmlFor="tag" className="faFont customLabel">
                                هشتگ ها
                            </label>
                            <input type="text" name="tag" id="tag" className="customInput faFont tagInput" placeholder="هشتگ را وارد کنید و افزودن را بزنید." onKeyDown={(e)=>e.key.toLowerCase() === "enter" && addTagListener(e)} autoComplete="off"/>
                            <button className="addHashTagBtn faFont" type="button" onClick={()=>addTagListener()}>
                                افزودن
                            </button>
                        </fieldset>
                        {
                            uploadInfo.tagsList &&  uploadInfo.tagsList.length > 0 && <div className="flex tagsList">
                                {
                                    uploadInfo.tagsList.map((item,index)=>{
                                        return <button className="tagItemBtn faFont flex alignCenter gap5" type="button" key={index} onClick={()=>removeTagListener(item)}>
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.243 3.03a1 1 0 01.727 1.213L9.53 6h2.94l.56-2.243a1 1 0 111.94.486L14.53 6H17a1 1 0 110 2h-2.97l-1 4H15a1 1 0 110 2h-2.47l-.56 2.242a1 1 0 11-1.94-.485L10.47 14H7.53l-.56 2.242a1 1 0 11-1.94-.485L5.47 14H3a1 1 0 110-2h2.97l1-4H5a1 1 0 110-2h2.47l.56-2.243a1 1 0 011.213-.727zM9.03 8l-1 4h2.938l1-4H9.031z" clipRule="evenodd"></path></svg>
                                            {
                                                item
                                            }
                                            <span className="removeTagSpan flex flexCenter">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
                                            </span>
                                        </button>
                                    })
                                }
                            </div>
                        }
                        {
                            errors.tags && <p className="faFont profileErrorText">
                                {
                                    errors.tags
                                }
                            </p>
                        }
                    </div>
                    <button className="submitFormBtn faFont flex flexCenter" onClick={()=>submitFormBtn()}>
                        ارسال ویدیو
                    </button>
                </form>
                <div className={`flex flexCol uploadingDiv ${uploadingMode.active === "progress" ? "showUploadProgress" : ""}`}>
                    <h3 className="faFont uploadTitle flex alignCenter gap5">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path></svg>
                        افزودن ویدیو جدید
                    </h3>
                    <div className="flex flexCol uploadContentDiv">
                        <div className="uploadProgressDiv rel">
                            <img src="/image/wave1.webp" alt="wave" className="uploadWave1" draggable="false"/>
                            <img src="/image/wave2.webp" alt="wave" className="uploadWave2" draggable="false"/>
                            <p className="faFont uploadPercentText flex flexCenter">
                                {
                                    uploadingMode.percent
                                }%
                            </p>
                        </div>
                        <p className="faFont uploadingText">
                            در حال آپلود فایل
                        </p>
                        <p className="faFont uploadingText2">
                            لطفا شکیبا باشید
                        </p>
                    </div>
                    <button className="cancelUploadBtn faFont flex flexCenter" onClick={()=>{if(xhr.current) {xhr.current.abort(); setUploadingMode({active:"form",percent : 0}); showModal("آپلود ویدیو لغو شد.") }}}>
                        لغو آپلود
                    </button>
                </div>
            </div>
        }
    </>
}