import React, { useContext, useEffect, useRef, useState } from "react";
import "../Style/cart.css";
import NavsList from "./NavsList";
import { Link } from "react-router-dom";
import { cutText, scrollToTop, showModal } from "./Methods";
import { AppContext } from "../App";
import CustomImage from "./CustomImage";
import AddToCart from "./AddToCart";

export default function Cart() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [cartPrice, setCartPrice] = useState({
    finalPrice: "-",
    totalDiscount: "-",
    totalPrice: "-",
  });
  const firstLoad = useRef(true);
  const {
    cartContext: [cartData, setCartData],
    userData: [userInfo],
  } = useContext(AppContext);
  useEffect(() => {
    scrollToTop();
    if (firstLoad.current && userInfo.tkn) {
      firstLoad.current = false;
      fetchData();
    }
  }, [userInfo]);
  useEffect(() => {
    calculateCartPrice();
    setData(cartData);
  }, [cartData]);
  document.title = "جزئیات سبد خرید";
  function fetchData() {
    if (!userInfo.tkn) {
      return;
    }
    const formData = new FormData();
    formData.append("token", userInfo.tkn);
    fetch("https://crm.bamamiresi.com/Enrollment/IGetUserBasket", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        if (json.error) {
          throw new Error(json.msg);
        }
        setLoading(false);
        const serverResponse = json;
        if (serverResponse) {
          setData(serverResponse);
        }
      })
      .catch((err) => {});
  }
  function calculateCartPrice() {
    let tempFinalPrice = 0;
    let temptotalDiscount = 0;
    let temptotalPrice = 0;
    cartData.forEach((item) => {
      if (+item.discountPrice > 0) {
        tempFinalPrice +=
          (+item.price - (+item.price * +item.discountPrice) / 100) *
          +item.quantity;
        temptotalDiscount +=
          ((+item.price * +item.discountPrice) / 100) * +item.quantity;
      } else {
        tempFinalPrice += +item.price * +item.quantity;
      }
      temptotalPrice += +item.price * +item.quantity;
    });
    setCartPrice({
      finalPrice: tempFinalPrice,
      totalDiscount: temptotalDiscount,
      totalPrice: temptotalPrice,
    });
  }
  function changeCart(number, type, id) {
    const data = new FormData();
    data.append("token", userInfo.tkn);
    data.append("PaymentType", 1);
    data.append("productId", id);
    data.append("Quantity", number);
    data.append("orderType", type);
    data.append("ClassID", 0);
    fetch("https://crm.bamamiresi.com/Enrollment/IAddToBasket", {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("102");
        }
        return res.json();
      })
      .then((json) => {
        const serverRes = json[0];
        if (+serverRes.code !== 200) {
          throw new Error(
            serverRes.message ? serverRes.message : "عدم ارتباط با سرور"
          );
        }
        setCartData(serverRes.basket);
        console.log(serverRes);
      })
      .catch((err) => {
        showModal(err.message);
      });
  }
  return (
    <>
      {loading ? (
        <section className="cartPageLoadingSection grid container">
          <div className="cartPageDetailsLoading flex flexCol rel">
            <div className="loaderItem"></div>
          </div>
          <div className="cartPageSummaryLoading flex flexCol rel">
            <div className="loaderItem"></div>
          </div>
        </section>
      ) : (
        <>
          <NavsList data={[{ name: "سبد خرید", link: "/cart" }]} />
          {data.length > 0 ? (
            <section className="cartPageMainSection grid container">
              <div className="flex flexCol cartPageRightDiv">
                <h1 className="faFont cartPageTitle">جزئیات سبد خرید</h1>
                <div className="flex flexCol cartPageDetailsList">
                  {data &&
                    data.map((item, index) => {
                      return (
                        <div
                          className="cartDetailsItem grid"
                          key={item.product[0].id}
                        >
                          <Link
                            to={item.product[0].url}
                            target="_blank"
                            className="flex flexCol"
                            draggable="false"
                          >
                            <CustomImage
                              data={{
                                src: item.product[0].src,
                                alt: item.product[0].title,
                                class: "cartItemImg",
                                height: 70,
                                width: 70,
                              }}
                            />
                          </Link>
                          <div className="flex flexCol cartItemInfoDiv">
                            <Link
                              to={item.product[0].url}
                              className="faFont textClr3 cartItemInfoLink"
                              draggable="false"
                            >
                              {item.product[0].title}
                            </Link>
                            {+item.discountPrice > 0 ? (
                              <div
                                className={
                                  "flex alignCenter justifyBetween cartItemDiscountDiv"
                                }
                              >
                                <p className="cartItemDiscountPercent faFont flex alignCenter justifyBetween">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 384 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M109.25 173.25c24.99-24.99 24.99-65.52 0-90.51-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 25 25 65.52 25 90.51 0zm256 165.49c-24.99-24.99-65.52-24.99-90.51 0-24.99 24.99-24.99 65.52 0 90.51 24.99 24.99 65.52 24.99 90.51 0 25-24.99 25-65.51 0-90.51zm-1.94-231.43l-22.62-22.62c-12.5-12.5-32.76-12.5-45.25 0L20.69 359.44c-12.5 12.5-12.5 32.76 0 45.25l22.62 22.62c12.5 12.5 32.76 12.5 45.25 0l274.75-274.75c12.5-12.49 12.5-32.75 0-45.25z"></path>
                                  </svg>
                                  {+item.discountPrice}
                                </p>
                                <p className="cartItemBeforeDiscountPrice faFont textClr7">
                                  {(+item.price).toLocaleString()}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                            <p className="cartItemFinalPrice faFont textClr3">
                              {(
                                +item.price -
                                (+item.price * +item.discountPrice) / 100
                              ).toLocaleString()}
                              <span>تومان</span>
                            </p>
                            <div className="flex alignCenter justifyBetween cartItemManageDiv">
                              <button
                                className="cartItemRemoveBtn faFont textClrF flex flexCenter"
                                onClick={() =>
                                  changeCart(
                                    0,
                                    item.OrderType,
                                    item.product[0].id
                                  )
                                }
                              >
                                حذف
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                              <AddToCart
                                data={{
                                  id: item.product[0].id,
                                  type: item.type,
                                  number: item.quantity,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="flex flexCol gap10">
                <div className="flex flexCol cartPageLeftDiv">
                  <div className="summaryItemDiv flex alignCenter justifyBetween">
                    <p className="faFont textSize1 textClr7 summaryTitle">
                      قیمت :
                    </p>
                    <p className="faFont summaryPriceText flex alignCenter gap5">
                      {cartPrice.totalPrice.toLocaleString()}
                      <span>تومان</span>
                    </p>
                  </div>
                  <div className="summaryItemDiv flex alignCenter justifyBetween">
                    <p className="faFont textSize1 textClr7 summaryTitle">
                      تخفیف :
                    </p>
                    <p className="faFont summaryPriceText flex alignCenter gap5">
                      {cartPrice.totalDiscount.toLocaleString()}
                      <span>تومان</span>
                    </p>
                  </div>
                  <div className="summaryItemDiv flex alignCenter justifyBetween">
                    <p className="faFont textSize1 textClr7 summaryTitle">
                      مبلغ نهایی :
                    </p>
                    <p className="faFont summaryPriceText flex alignCenter gap5">
                      {cartPrice.finalPrice.toLocaleString()}
                      <span>تومان</span>
                    </p>
                  </div>
                  <Link
                    to="/submitCart"
                    className="submitCartLink faFont textSize11 textClrF flex flexCenter"
                  >
                    تایید و ادامه
                  </Link>
                </div>
                <div className="grid productSupportDiv">
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/support.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      پشتیبانی 24 ساعته
                    </span>
                  </div>
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/delivery.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon2"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      ارسال به سراسر کشور
                    </span>
                  </div>
                  <div className="flex flexCol flexCenter profileSupportItem">
                    <object
                      data="/icon/guarantee.svg"
                      type="image/svg+xml"
                      width="60"
                      height="60"
                      className="profileSupportIcon"
                    >
                      Your browser does not support SVGs.
                    </object>
                    <span className="faFont profileSupportText">
                      پرداخت از طریق درگاه
                    </span>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <>
              <section className="flex flexCol flexCenter emptyCartSection container">
                <svg
                  className="emptyCartIcon"
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 256 256"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M232,72l-25.63,92.28A16,16,0,0,1,191,176H92.16a16,16,0,0,1-15.41-11.72L51.11,72Z"
                    opacity="0.2"
                  ></path>
                  <path d="M104,216a16,16,0,1,1-16-16A16,16,0,0,1,104,216Zm88-16a16,16,0,1,0,16,16A16,16,0,0,0,192,200ZM239.71,74.14l-25.64,92.28A24.06,24.06,0,0,1,191,184H92.16A24.06,24.06,0,0,1,69,166.42L33.92,40H16a8,8,0,0,1,0-16H40a8,8,0,0,1,7.71,5.86L57.19,64H232a8,8,0,0,1,7.71,10.14ZM221.47,80H61.64l22.81,82.14A8,8,0,0,0,92.16,168H191a8,8,0,0,0,7.71-5.86Z"></path>
                </svg>
                <h2 className="faFont textSize14 textClr7 emptyCartTitle">
                  سبد خرید شما خالی است.
                </h2>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}
